import React from "react";

const LendingFamous = () => {
  return (
    <div className="lending-famous-div">
      <div className="lend-fam-inner">
        <div></div>
        <div className="lend-fam-content">
          <div className="lend-fam-title">
            <div className="lend-fam-hero">
              <p className="lend-fam-title-main">Start like Famous</p>
              <p className="lend-fam-sub">
                Start your own P2P Lending website like LendingClub, Prosper
                Marketplace, Upstart, Funding Circle, Kiva, Zopa and SoFi.
              </p>
            </div>
            <div className="lend-fam-btn">
              <button className="lend-fam-btn-main">KNOW MORE</button>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default LendingFamous;
