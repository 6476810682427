import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";

const FaqsQA = ({ data }) => {
  return (
    <Container>
      <div className="faqs-qa">
        <div className="faqs-qa-inner">
          <div className="faqs-qa-title">
            <p className="faq-qa-title-main">{data.title}</p>
          </div>
          <div className="faqs-qa-accordion">
            <div className="faqs-qa-acc-inner">
              {data.content.map((item, index) => (
                <Accordion
                  key={index}
                  className="accordion-style"
                  style={{
                    WebkitBorderRadius: "10px",
                    border: "1px solid #00000016",
                  }}
                >
                  <AccordionSummary
                    style={{ minHeight: "4rem" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      color="#505050"
                      className="faq-qa-title"
                    >
                      <strong>{item.question}</strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography color="#505050">{item.ans}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FaqsQA;
