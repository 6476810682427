import React, { useEffect, useState } from "react";
import Banner from "components/core/Banner";
import Demopage from "./Demopage";
import { banner4, demos3 } from "assets";
import DemoTabs from "components/core/DemoTabs";
import DemoOverview from "./DemoOverview";
import { NavLink, useParams } from "react-router-dom";

const Reward = () => {
  const [value, setValue] = useState(0);
  const { type } = useParams();
  useEffect(() => {
    switch (type) {
      case "demo":
        setValue(3);
        break;
      case "pricing":
        setValue(2);
        break;
      case "features":
        setValue(1);
        break;

      default:
        setValue(0);
    }
    return () => {
      setValue(0);
    };
  }, [type]);
  return (
    <>
      <div className="reward-div">
        <div className="reward-inner">
          <Banner
            isIntegration={true}
            title={
              <>
                <p className="hero">
                  Reward
                  <span className="crowd-div-realestate"> Fundraising </span>
                  Software or
                </p>
              </>
            }
            title2={
              <>
                <p className="hero">
                  <span className="crowd-div-realestate">
                    Reward Crowdfunding
                  </span>{" "}
                  Script
                </p>
              </>
            }
            subtitle={
              <>
                <p className="real-est-sub-main">
                  Create an online portal to attract pledges for your startup
                  venture without losing the ownership of the venture either
                  through our online crowdfunding platform.
                </p>
              </>
            }
            img={banner4}
            btn1={
              <>
                <NavLink to="/reward/pricing">
                  <button className="real-est-btn1-main">View Prices</button>
                </NavLink>
              </>
            }
            btn2={
              <>
                <NavLink to="/reward/features">
                  <button className="real-est-btn2-main">Check Features</button>
                </NavLink>
              </>
            }
          />
        </div>
      </div>
      <DemoTabs
        value={value}
        setValue={setValue}
        overview={
          <>
            <DemoOverview
              img={demos3}
              contents={[
                {
                  id: "1",
                  title: "INTRODUCTION TO REWARD-BASED CROWDFUNDING",
                  desc: (
                    <>
                      As the name suggests, Reward Crowdfunding is a system
                      wherein people invest in a project in order to gain
                      rewards or perks for their pledge towards the project. In
                      reward based fundraising, a group of people support a
                      specific venture proposed by a project creator through
                      pledges. Unlike in equity crowdfunding system, the project
                      owner holds 100% ownership of the venture funded through
                      rewards based crowdfunding.
                    </>
                  ),
                },
                {
                  id: "2",
                  title: "WHAT IS REWARD-BASED CROWDFUNDING SCRIPT?",
                  desc: (
                    <>
                      Our Reward{" "}
                      <span className="demo-cont-span">
                        Crowdfunding software
                      </span>{" "}
                      enables you to set up an online portal to attract pledges
                      for your startup venture without losing the ownership of
                      the venture either through our online Crowdfunding portal
                      approach or under the{" "}
                      <span className="demo-cont-span">
                        Crowdfunding DIY Approach
                      </span>
                      . With the help of this software, you can set up a
                      full-fledged Reward-based Crowdfunding platform that
                      allows individuals, companies or organizations to post
                      projects and invite pledges from people online in return
                      for rewards or perks.
                    </>
                  ),
                },
              ]}
              socialMediaProps={{
                title: "HOW DOES THE REWARD CROWDFUNDING SOFTWARE HELP YOU?",
                desc: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        At FundraisingScript.com, we understand the basic
                        requirement for a rewards based Crowdfunding platform
                        and hence we have developed a PHP crowdfunding site that
                        takes care of all the advanced security measures to help
                        you to run a clean, secured and simple rewards-based
                        Crowdfunding program on your web portal to help
                        individuals, companies or organizations to invite
                        pledges for their ventures.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Despite having advanced features, our experts have taken
                        special care to keep the interface user-friendly in
                        order to offer the best user experience for everyone,
                        right from admin to users who are looking for a highly
                        secure workflow. We have also ensured that the script
                        builds a responsive website with fast loading time; so
                        that anyone can access it from their mobile phone or
                        tablet without any trouble.
                      </p>
                    </div>
                  </>
                ),
                title2: "HOW DOES THE REWARD CROWDFUNDING SCRIPT WORK?",
                desc2: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Set up your own Kickstarter, Indiegogo, or GoFundMe
                        clone using our Reward based crowd investing script.
                        Once installed, this software will enable the website to
                        let individuals, companies or organizations to list
                        their projects and invite reward-based crowdfunding from
                        people. All projects will pass through a review process
                        by the Site Admin and will go live only after the admin
                        approves the project.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Once the project is published, it is ready to receive
                        pledges from interested people. Individuals can visit
                        and review different projects listed on the site and
                        choose the right one for them to sign Pledges towards
                        the cause in return for rewards or perks.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        The rewards or perks will be determined by the project
                        owner and mentioned on the project details page. Both,
                        project owner as well as site admin, benefit from all
                        the pledges received through the portal. Funds collected
                        will be split into two parts in a pre-assigned
                        percentage; one part would go to the website
                        administrator while the other would go to the project
                        owner. Site admin has the privilege to set the
                        percentage of share from the funds received via pledges
                        for each project listed on the portal.
                      </p>
                    </div>
                  </>
                ),
              }}
            />
          </>
        }
        demoTab={
          <Demopage
            isAppStore={true}
            isGooglePlay={true}
            info={
              <>
                Reward based crowdfunding platform is designed and developed in
                PHP specially to meet your requirements and fulfill your dream
                of a successful Reward based crowdfunding site. Start your
                reward crowdfunding platform, Our PHP based crowdfunding site is
                easy to manage and host projects.
              </>
            }
            cardInfo={[
              {
                title: "Default Theme",
                subtitle: (
                  <p className="demo-card-para-main">
                    <span className="demo-card-para-ref"></span>.
                  </p>
                ),
                img: demos3,
                btn: (
                  <a
                    href="https://reward.yardfundraising.searchingyard.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
              {
                title: "Classic Theme",
                subtitle: (
                  <p className="demo-card-para-main">
                    <span className="demo-card-para-ref"></span>.
                  </p>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-reward-classic.png",
                btn: <button className="demo-card-btn-main">Try Demo</button>,
              },
              {
                title: "Admin Demo",
                subtitle: (
                  <div className="demo-card-para2">
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Username: </span>{" "}
                        admin
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Password:</span>{" "}
                        admin
                      </p>
                    </div>
                  </div>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-reward-a.png",
                btn: (
                  <a
                    href="https://yardfundrasing-admin.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
            ]}
          />
        }
      />
      {/* <Demopage
        isAppStore={true}
        isGooglePlay={true}
        info={
          <>
            Reward based crowdfunding platform is designed and developed in PHP
            specially to meet your requirements and fulfill your dream of a
            successful Reward based crowdfunding site. Start your reward
            crowdfunding platform, Our PHP based crowdfunding site is easy to
            manage and host projects.
          </>
        }
        cardInfo={[
          {
            title: "Default Theme",
            subtitle: (
              <p className="demo-card-para-main">
                <span className="demo-card-para-ref"></span>.
              </p>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-reward-f.png",
            btn: (
              <a
                href="https://reward.yardfundrasing.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="demo-card-btn-main">Try Demo</button>
              </a>
            ),
          },
          {
            title: "Classic Theme",
            subtitle: (
              <p className="demo-card-para-main">
                <span className="demo-card-para-ref"></span>.
              </p>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-reward-classic.png",
            btn: <button className="demo-card-btn-main">Try Demo</button>,
          },
          {
            title: "Admin Demo",
            subtitle: (
              <div className="demo-card-para2">
                <div>
                  <p>
                    <span className="demo-card-admin-text">Username: </span>{" "}
                    admin
                  </p>
                </div>
                <div>
                  <p>
                    <span className="demo-card-admin-text">Password:</span>{" "}
                    admin
                  </p>
                </div>
              </div>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-reward-a.png",
            btn: <button className="demo-card-btn-main">Try Demo</button>,
          },
        ]}
      /> */}
    </>
  );
};

export default Reward;
