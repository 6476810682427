import BannerCard from "components/core/BannerCard";
import React from "react";

const WorkBanner = () => {
  return (
    <div className="work-banner">
      <div className="work-banner-inner">
        <BannerCard
          title="Our Works"
          sub="Want to see what we've done in the past? You may find something you would like."
        />
      </div>
    </div>
  );
};

export default WorkBanner;
