import React from "react";
import EmployWelfare from "./EmployWelfare";

const Employee = () => {
  return (
    <div className="employee-div">
      <div className="emp-inner">
        <div className="emp-banner">
          <div className="emp-banner-left">
            <p className="emp-banner-text">EMPLOYEE CROWDFUNDING</p>
          </div>
          <div className="emp-banner-right">
            <img
              className="emp-banner-img"
              src="https://d1suf988f4fj5v.cloudfront.net/images/img_demo.png"
              alt=""
            />
          </div>
        </div>
        <div className="emp-intro">
          <div className="emp-intro-inner">
            <div className="emp-intro-title">
              <p className="emp-intro-title-main">
                INTRODUCTION TO EMPLOYEE WELFARE CROWDFUNDING
              </p>
            </div>
            <div className="emp-intro-sub">
              <div className="emp-intro-sub-left">
                <p className="emp-intro-para">
                  Crowdfunding is a newly adapted system where a large number of
                  people come together and invest funds or donate into a single
                  project or venture through using internet platform. This kind
                  of practice is most popularly known as Crowdfunding because
                  the funds are basically generated from the crowd on the
                  internet.
                </p>
                <p className="emp-intro-para">
                  There are multiple applications of Crowdfunding today where
                  such practice is in motion for different goals such as Equity
                  fundraising, Real Estate Investments, Donations, Pledges or
                  even Loans. Overtime, these applications are even customized
                  to develop new channels of Crowdfunding as generating funds
                  from the crowd is the simplest and easiest mode of funding a
                  project today.
                </p>
                <p className="emp-intro-para">
                  With the help of such custom Crowdfunding platform
                  Fundraisingscript.com is introducing new concept and
                  application of Employee Welfare Crowdfunding also known as
                  Employee Crowd Funding or Employee Funding platform where a
                  project is created by an Employee of the company either for
                  charity or loan purpose with an intention to raise funding
                  through other Employees/Co-workers of the same company from
                  all branches/franchises over the globe.
                </p>
                <p className="emp-intro-para">
                  The concept of Employee Crowdfunding scheme is quite similar
                  to that of the traditional practice of Crowdfunding with the
                  only difference that with Employee Welfare Crowdfunding, the
                  funds are generated from other Employees within the company.
                </p>
              </div>
              <div className="emp-intro-sub-right">
                <img
                  className="emp-intro-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/what-employee.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployWelfare />
    </div>
  );
};

export default Employee;
