import React from "react";
import { Container, Typography } from "@mui/material";
import { donation, employee, energy, lending } from "assets";
// import { useNavigate } from "react-router-dom";

const solutionArr = [
  {
    img: lending,
    title: "Small business",
    desc: "Trust attracts funds",
  },

  {
    img: employee,
    title: "Statups",
    desc: "One for all and all for one",
  },

  {
    img: energy,
    title: "Enterprise",
    desc: "Your penny towards a better future",
  },
  {
    img: donation,
    title: "Government",
    desc: " one has become poor by giving",
  },
];

const Solution = () => {
  // const navigate = useNavigate();
  return (
    <Container className="main-new-solution-container d-flex justify-cnt">
      <div className="new-solution-div-wrapper">
        {solutionArr.map((curElm, index) => {
          return (
            <section
              className="new-sol-grid-item d-flex  align-cnt"
              key={index}
              //   onClick={() => curElm.default && navigate(curElm.default)}
            >
              {/* <div className="left-solution-div mr-1">
                    <img src={curElm.img} alt="business-logo" />
                  </div> */}

              <div className="right-solution-div d-flex justify-btw align-cnt w-100">
                <div>
                  <Typography variant="body1" className="font-size-0_8">
                    <p className="new-sol-nav-title">{curElm.title}</p>
                  </Typography>
                </div>
                {/* 
                <span className="solution-right-icon-span ">
                  <NavigateNextIcon className=" font-size-0_9 " />
                </span> */}
              </div>
            </section>
          );
        })}
      </div>
    </Container>
  );
};

export default Solution;
