import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  // kp1,
  // kp2,
  // kp3,
  // kp4,
  // kp5,
  // kp6,
  // kp7,
  // kp8,
  // kp9,
  // kp10,
  // kp11,
  // kp12,
  // kp13,
  // kp14,
  // kp15,
  // kp16,
  // kp17,
  // kp18,
  // kp19,
  // kp20,
  // kp21,
  // kp22,
  // kp23,
  // kp24,
  // kp25,
  // kp26,
  // kp27,
  // kp28,
  // kp29,
  // kp30,
  // kp31,
  // kp32,
  // kp33,
  // kp34,
  // kp35,
  // kp36,
  // kp37,
  // kp38,
  // kp39,
  // kp40,
  // kp41,
  // kp42,
  // kp43,
  // kp44,
  donation,
  employee,
  energy,
  equity,
  lending,
  realestate,
  reward,
} from "assets";

import { Divider, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NavLink } from "react-router-dom";

const solutionArr = [
  {
    img: realestate,
    title: "Real Estate CrowdFunding",
    path: "/realestate/demo",
  },
  {
    img: lending,
    title: "P2P Lending",
    path: "/lending",
  },
  {
    img: equity,
    title: "Equity CrowdFunding",
    path: "/equity/demo",
  },
  {
    img: employee,
    title: "Employee CrowdFunding",
    path: "employee",
  },
  {
    img: reward,
    title: "Reward CrowdFunding",
    path: "/reward/demo",
  },
  {
    img: energy,
    title: "Renewable Energy CrowdFunding",
    path: "/energy",
  },
  {
    img: donation,
    title: "Donation CrowdFunding",
    path: "/donation/demo",
  },
];

// const integrationArr = [
//   {
//     id: 1,
//     title: "Payment Gateway",
//     child: [
//       {
//         icon: kp1,
//         name: "Paypal",
//       },
//       {
//         icon: kp3,
//         name: "Plaid",
//       },
//       {
//         icon: kp4,
//         name: "LemonWay",
//       },
//       {
//         icon: kp6,
//         name: "Assembly",
//       },
//       {
//         icon: kp8,
//         name: "Uphold",
//       },
//       {
//         icon: kp20,
//         name: "Prime Trust",
//       },
//       {
//         icon: kp17,
//         name: "CC Avenue",
//       },
//       {
//         icon: kp15,
//         name: "Paystack",
//       },
//       {
//         icon: kp13,
//         name: "Braintree",
//       },
//       {
//         icon: kp2,
//         name: "Stripe",
//       },
//       {
//         icon: kp19,
//         name: "MongoPay",
//       },
//       {
//         icon: kp5,
//         name: "Goji",
//       },
//       {
//         icon: kp7,
//         name: "Escrow",
//       },
//       {
//         icon: kp9,
//         name: "Go",
//       },
//       {
//         icon: kp18,
//         name: "FundAmerica",
//       },
//       {
//         icon: kp16,
//         name: "TNG wallet",
//       },
//       {
//         icon: kp14,
//         name: "WePay",
//       },
//       {
//         icon: kp12,
//         name: "Dwolla",
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: "Cryptocurrency",
//     child: [
//       {
//         icon: kp11,
//         name: "NOWPayments",
//       },
//       {
//         icon: kp10,
//         name: "BitPay",
//       },
//       {
//         icon: kp34,
//         name: "GoUrl.io",
//       },
//       {
//         icon: kp28,
//         name: "CoinsPaid",
//       },
//       {
//         icon: kp27,
//         name: "Coinbase",
//       },
//       {
//         icon: kp26,
//         name: "Blockonomics",
//       },
//       {
//         icon: kp25,
//         name: "CoinGates",
//       },
//       {
//         icon: kp24,
//         name: "Blockchain",
//       },
//       {
//         icon: kp23,
//         name: "SpectroCoin",
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: "KYC & AML",
//     child: [
//       {
//         icon: kp22,
//         name: "SumSub",
//       },
//       {
//         icon: kp28,
//         name: "SuftiPro",
//       },
//       {
//         icon: kp19,
//         name: "Mongopay",
//       },
//       {
//         icon: kp4,
//         name: "LemonWay",
//       },
//       {
//         icon: kp21,
//         name: "GBG GreenID",
//       },
//       {
//         icon: kp40,
//         name: "Jumio",
//       },
//       {
//         icon: kp18,
//         name: "FundAmerica",
//       },
//       {
//         icon: kp39,
//         name: "North Capital",
//       },
//       {
//         icon: kp38,
//         name: "Trulio",
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: "E-Signature",
//     child: [
//       {
//         icon: kp37,
//         name: "HelloSign",
//       },
//       {
//         icon: kp35,
//         name: "SecFly",
//       },
//       {
//         icon: kp33,
//         name: "DocuSign",
//       },
//       {
//         icon: kp32,
//         name: "GetAccept",
//       },
//     ],
//   },
//   {
//     id: 5,
//     title: "CRM",
//     child: [
//       {
//         icon: kp31,
//         name: "ActiveCampaign",
//       },
//       {
//         icon: kp30,
//         name: "HubSpot",
//       },
//       {
//         icon: kp29,
//         name: "SalesForce",
//       },
//     ],
//   },
//   {
//     id: 6,
//     title: "Analytics",
//     child: [
//       {
//         icon: kp44,
//         name: "Google Analytics",
//       },
//       {
//         icon: kp30,
//         name: "HubSpot",
//       },
//     ],
//   },
//   {
//     id: 7,
//     title: "Accreditation",
//     child: [
//       {
//         icon: kp43,
//         name: "InvestReady",
//       },
//       {
//         icon: kp42,
//         name: "VerifyInvestor",
//       },
//     ],
//   },
//   {
//     id: 8,
//     title: "Social Media Signup",
//     child: [
//       {
//         icon: kp41,
//         name: "Oneall",
//       },
//       {
//         icon: kp36,
//         name: "AuthO",
//       },
//     ],
//   },
// ];

export default function NestedList() {
  // const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  // const [ids, setIds] = React.useState([]);

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  const handleClick1 = () => {
    setOpen1(!open1);
  };

  // const handleClickChild = (reqId) => {
  //   // const newIdArr = [];
  //   // const filterName = integrationArr.filter((item) => item.id === reqId);

  //   if (ids.includes(reqId)) {
  //     setIds(ids.filter((item) => item !== reqId));
  //     return;
  //   } else {
  //     //   newIdArr.push(reqId);
  //     setIds([...ids, reqId]);
  //     //   console.log(ids);
  //   }
  // };
  // console.log(ids);

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick1}>
        <ListItemText primary="Products" />
        {open1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {solutionArr.map((curElm, index) => {
            return (
              <ListItemText key={index}>
                <NavLink to={curElm.path}>
                  <ListItemButton>
                    <div className="d-flex align-cnt justify-btw w-100 clr-light">
                      <div className="d-flex align-cnt">
                        <img src={curElm.img} alt="logo" className="mr-1" />
                        <Typography variant="body1">{curElm.title}</Typography>
                      </div>
                      <div>
                        <NavigateNextIcon />
                      </div>
                    </div>
                  </ListItemButton>
                </NavLink>
                <Divider />
              </ListItemText>
            );
          })}
        </List>
      </Collapse>
      <Divider />

      {/* <ListItemButton onClick={handleClick}>
        <ListItemText primary="Integration" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {integrationArr.map((curElm) => {
          return (
            <ListItemText key={curElm.id}>
              <div className=" clr-light">
                <ListItemButton
                  className="d-flex align-cnt justify-btw w-100"
                  onClick={() => {
                    handleClickChild(curElm.id);
                  }}
                >
                  <ListItemText primary={curElm.title} />

                  {ids.includes(curElm.id) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider />
                <Collapse
                  in={ids.includes(curElm.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid container spacing={2}>
                    {curElm.child.map((curChild, index) => {
                      return (
                        <Grid item xs={6} sm={6} key={index}>
                          <ListItemButton className="payment-child-items">
                            <img src={curChild.icon} alt="icon" />

                            <ListItemText primary={curChild.name} />
                          </ListItemButton>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Collapse>
              </div>
              <Divider />
            </ListItemText>
          );
        })}
        </List>
      </Collapse> */}

      <Divider />
      <NavLink to="/">
        <ListItemButton>
          <ListItemText primary="Solution" />
        </ListItemButton>
      </NavLink>
      <Divider />

      <NavLink to="/price">
        <ListItemButton>
          <ListItemText primary="Pricing" />
        </ListItemButton>
      </NavLink>
      <Divider />

      <NavLink to="/faqs">
        <ListItemButton>
          <ListItemText primary="FAQs" />
        </ListItemButton>
      </NavLink>
      <Divider />

      <NavLink to="/work">
        <ListItemButton>
          <ListItemText primary="Our Work" />
        </ListItemButton>
      </NavLink>
      <Divider />

      <NavLink to="/contact">
        <ListItemButton>
          <ListItemText primary="Contact Us" />
        </ListItemButton>
      </NavLink>
      <Divider />
      <NavLink to="/request">
        <ListItemButton>
          <ListItemText primary="Request A Demo" />
        </ListItemButton>
      </NavLink>
      <Divider />
    </List>
  );
}
