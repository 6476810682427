import LendingFamous from "pages/LendingFamous";
import React from "react";
import { NavLink } from "react-router-dom";
import LendingFeature from "./LendingFeature";

const Lending = () => {
  return (
    <>
      <div className="lending-outer-div"></div>
      <div className="lending-div">
        <div className="lending-inner">
          <div></div>
          <div className="lending-hero">
            <div className="lending-hero-title">
              <p className="lending-hero-title-main">
                P2P Lending Platform Software
              </p>
            </div>
            <div className="lending-hero-sub">
              <p className="lending-hero-sub-main">
                Looking to start your own P2P lending platform? Start P2P
                Lending platform now with Fundraisingscript.com. Top-notch,
                fully featured and stable P2P Lending Software to help you start
                your next fintech.
              </p>
            </div>
            <div className="lending-hero-btn">
              <NavLink to="/contact">
                <button className="lending-hero-btn-main">KNOW MORE</button>
              </NavLink>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <LendingFeature />
      <LendingFamous />
    </>
  );
};

export default Lending;
