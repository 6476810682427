import React from "react";

const EmployWelfare = () => {
  return (
    <div className="emp-wlf-outer">
      <div className="emp-wlf">
        <div className="emp-wlf-inner">
          <div className="emp-wlf-title">
            <p className="emp-wlf-title-main">
              WHY EMPLOYEE WELFARE CROWDFUNDING?
            </p>
          </div>
          <div className="emp-wlf-sub">
            <p className="emp-wlf-para">
              Although it’s a new concept, some companies around the globe are
              adapting with this new system where employees in need of monetary
              help can post a new project and invite employee-based Crowdfunding
              in order to generate fund from their own colleagues within the
              company.
            </p>
            <p className="emp-wlf-para">
              The concept is fruitful in many ways as getting a loan from banks
              can be a tough challenge in the complex world today as banks
              nowadays sanction loans after verifying several factors such as
              income, credit scores, bank statements etc. On the other hand,
              Employee Crowdfunding is free of all such hassles and with the
              company itself managing the overall process, the system is
              trustworthy enough for everyone who volunteers to lend money for
              the project.
            </p>
            <p className="emp-wlf-para">
              Another major reason why employee welfare Crowdfunding is
              beneficial is that this platform can also be used to generate
              funds for charity in order to raise the reputation of the company
              as well as awareness of a good cause or campaign both within the
              company and the outer world.
            </p>
          </div>
          <div className="emp-wlf-title2">
            <p className="emp-wlf-title-main2">
              KEY BENEFITS OF EMPLOYEE WELFARE CROWDFUNDING
            </p>
          </div>
          <p className="emp-wlf-para">
            Here are some of the key benefits of Employee Welfare Crowdfunding:
          </p>
          <div className="for-emp">
            <p className="bold-text">For Employees:</p>
          </div>
          <div className="for-emp-sub">
            <div className="for-em-sub-div">
              <ul className="for-em-sub-main">
                <li>
                  <p className="simple-para">
                    Get funding from friends and colleagues within the company
                  </p>
                </li>
                <li>
                  <p className="simple-para">
                    No need to wait for loan approval at banking or other
                    financial institutions
                  </p>
                </li>
                <li>
                  <p className="simple-para">
                    Raise awareness about a charity campaign
                  </p>
                </li>
                <li>
                  <p className="simple-para">
                    Hassle-free, documentation free, simple and sound loan
                    application process
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="for-emp">
            <p className="bold-text">For Companies:</p>
          </div>
          <div className="for-em-sub-div">
            <ul className="for-em-sub-main">
              <li>
                <p className="simple-para">
                  Build community within the organization
                </p>
              </li>
              <li>
                <p className="simple-para">Make your employees happy</p>
              </li>
              <li>
                <p className="simple-para">
                  Build strong relations with the employees
                </p>
              </li>
              <li>
                <p className="simple-para">
                  Develop loyalty among the employees for the company
                </p>
              </li>
              <li>
                <p className="simple-para">
                  Gather employees together for a common cause in order to
                  spread awareness for the cause and thereby build strong
                  reputation for the company.
                </p>
              </li>
              <li>
                <p className="simple-para">Employee retention</p>
              </li>
              <li>
                <p className="simple-para">Low employee turnover</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="emp-imp-div">
        <div className="emp-imp-inner">
          <div className="emp-imp-title emp-wlf-title-main2">
            <p>
              HOW COMPANIES CAN IMPLEMENT EMPLOYEE WELFARE CROWDFUNDING SYSTEM?
            </p>
          </div>
          <div className="emp-imp-sub">
            <p className="simple-para">
              There are basically two main channels where Employee Welfare
              Crowdfunding practice can come handy in every large enterprise or
              organization. Companies can set up a Crowdfunding platform on
              their website and inform all their employees in detail about the
              platform and how it can be used for their own welfare.
            </p>
          </div>
          <div className="emp-imp-most">
            <p className="most-imp">
              Most commonly channels for Employee Welfare Crowdfunding are as
              follows:
            </p>
          </div>
          <div className="emp-imp-p-loan">
            <p className="bold-text">Personal Loan:</p>
          </div>
          <div className="emp-imp-p-sub">
            <p className="simple-para">
              Imagine an employee is planning to buy a car or home in the near
              future but is short of some funding. Either the employee will quit
              the plan or approach a bank for the loan. There are very low
              chances of getting a loan from the bank with strict guidelines
              adapted by most financial institutions. However, even if the
              employee gets the loan, the interest rates would be too high.
            </p>
          </div>
          <div className="emp-imp-p-sub">
            <p className="simple-para">
              What if, there is a system within the company where the employee
              can simply list his/her requirement over the Employee Welfare
              Crowdfunding platform and get funded by friends or colleagues
              within the company at the most economical interest rates. It will
              be fruitful to all as the employee would get the funding, other
              employees voluntarily lending the money can invest the money for
              better interest rates while the company would earn loyalty from
              all its employees.
            </p>
          </div>
          <div className="emp-imp-p-loan">
            <p className="bold-text">Charity:</p>
          </div>
          <div className="emp-imp-p-sub">
            <p className="simple-para">
              Another approach could be generating funds for charity. If the
              company or an employee of the company is supporting a good cause
              or charity program, they can list the project on the company’s
              employee welfare Crowdfunding platform and invite other employees
              to join and donate money for the great cause.
            </p>
          </div>
          <div className="emp-imp-p-sub">
            <p className="simple-para">
              With this program, the employees can benefit an act of goodness
              through charity while the company would earn reputation and
              develop its brand as a good hearted organization earning customer
              loyalty in the future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployWelfare;
