import React from "react";
import ContactBanner from "./ContactBanner";
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
  return (
    <div>
      <ContactBanner />
      <ContactInfo />
      {/* <ContactForm /> */}
    </div>
  );
};

export default ContactPage;
