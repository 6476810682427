export const requestMailContent = (username) => `
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tbody>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                            font-weight: 400;
                          "
                          valign="top"
                        >
                        
                        <strong>Dear ${username}</strong><br />
                        <p>Welcome to YardFundRaising, All-in-one crowdfunding software solution. Thank you for showing interest & registering yourself successfully for checking out the demo of our crowdfunding platform.
                        </p><br />
                        <p>We have received your request & forwarded your demo request to the team to process. Our team will soon get you back & share with you further details.
                        </p>
                        <br />
                        <p>You are almost ready to build your next generation fully featured fundraising platform, YardFundRaising. Let's grow together.
                        </p>
                        
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 12px;
                            line-height: 19px;
                            font-weight: 500;
                          "
                          valign="top"
                        >
                          <br />
                          <hr />
                          <br />
                          Thanks & Regards
                          <br />
                          <strong>Team YardFundraising. </strong> <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `;
export const contactMailContent = (username) => `
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tbody>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                            font-weight: 400;
                          "
                          valign="top"
                        >
                        <strong>Dear ${username},</strong><br /><br />
                        <p>Thanks for getting in touch with us. We will review your enquiry and you will be contacted from our side with further information to solve all your queries.
                        </p><br />
                        <p>If you have any further queries, we’ll get back to you, feel free to call us or hit reply and send them through.
                        </p><br />
                        </td>
                      </tr>

                    


                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 12px;
                            line-height: 19px;
                            font-weight: 500;
                          "
                          valign="top"
                        >
                          <br />
                          <hr />
                          <br />
                          Thanks & Regards
                          <br />
                          <strong>Team YardFundraising. </strong> <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `;
export const normalMailBody = (content, username) => `
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tbody>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                            font-weight: 400;
                          "
                          valign="top"
                        >
                        <strong>Dear ${username}</strong>,
                        <br /><br />
                        Thanks for getting in touch with us. We will review your enquiry and you will be contacted from our side with further information to solve all your queries.<br /><br />
                          ${content}
                          <br />
                        </td>
                      </tr>

                    


                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td align="left" valign="top">
          <table
            align="left"
            bgcolor="#FFFFFF"
            border="0"
            cellpadding="0"
            cellspacing="0"
            style="width: 650px"
            width="650"
          >
            <tbody>
              <tr>
                <td align="center" style="padding: 0px 25px" valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style="
                            font-family: 'inter', helvetica, sans-serif;
                            font-size: 12px;
                            line-height: 19px;
                            font-weight: 500;
                          "
                          valign="top"
                        >
                          <br />
                          <hr />
                          <br />
                          Thanks & Regards
                          <br />
                          <strong>Team YardFundraising. </strong> <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `;
