import { Container } from "@mui/material";
import {
  dem1,
  dem10,
  dem11,
  dem12,
  dem13,
  dem14,
  dem15,
  dem16,
  dem17,
  dem18,
  dem19,
  dem2,
  dem20,
  dem21,
  dem22,
  dem23,
  dem24,
  dem25,
  dem26,
  dem27,
  dem28,
  dem29,
  dem3,
  dem30,
  dem31,
  dem32,
  dem33,
  dem34,
  dem35,
  dem36,
  dem37,
  dem38,
  dem39,
  dem4,
  dem40,
  dem41,
  dem42,
  dem43,
  dem44,
  dem45,
  dem46,
  dem47,
  dem48,
  dem49,
  dem5,
  dem50,
  dem51,
  dem52,
  dem53,
  dem6,
  dem7,
  dem8,
  dem9,
} from "assets";
import DemoFeatureCard from "components/core/DemoFeatureCard";
import React from "react";

const DemoFeatures = () => {
  const demoCard = [
    {
      heading: "General Features",
      subhead:
        "Check out the general features of our equity crowdfunding platform software.",
      demos: [
        {
          title: "Responsive Design",
          sub: "Bootstrap design optimized to be able to used from multiple platforms.",
          img: dem1,
        },
        {
          title: "Invite Friends",
          sub: "Invite friends from social media account i.e Facebook ",
          img: dem2,
        },
        {
          title: "Alerts",
          sub: "User ability to keep updated with account activities alerts.",
          img: dem3,
        },
        {
          title: "Newsletter System",
          sub: "Easy to use news letter system to get in touch with users.",
          img: dem4,
        },
        {
          title: "Email Notification",
          sub: "User ability to enable/disable email notifications based on different campaigns, user and ownership levels.",
          img: dem5,
        },
        {
          title: "Featured Projects",
          sub: "Ability to feature projects along with static banner images in same slider on home page.",
          img: dem6,
        },
        {
          title: "Payment Gateway",
          sub: "Secure and multiple payment gateway integration i.e Paypal adaptive, Stripe and Wallet.",
          img: dem7,
        },
        {
          title: "KYC & AML Check",
          sub: "Easy & Instant Know Your Customer(KYC) and Anti Money Laundering(AML) check during user on-boarding via secure 3rd API for a seamless experience.",
          img: dem8,
        },
        {
          title: "Captcha",
          sub: "Captcha enabled signup form to prevent fake, automated/bot signups to keep away spams.",
          img: dem9,
        },
        {
          title: "Multi Language",
          sub: "Multi language support with almost all languages including RTL and LTR.",
          img: dem10,
        },
        {
          title: "User Follow",
          sub: "User ability to follow each other to grow friends and community on platform.",
          img: dem11,
        },
        {
          title: "Campaign Follow",
          sub: "User ability to follow favorite campaigns to get updated with campaign activities.",
          img: dem12,
        },
        {
          title: "Knowledge Base",
          sub: "Professionally designed and managed knowledge base/help center to help users to understand platform features.",
          img: dem13,
        },
        {
          title: "Progress Meter",
          sub: "Crowdfunding progress meter for each campaign to get an overview of campaign at first glance.",
          img: dem14,
        },
        {
          title: "Secure Platform",
          sub: "Secure and multi layer platform to prevent hacks, SQL injections and other vulnerabilities.",
          img: dem15,
        },
      ],
    },
    {
      heading: "Admin/Backend Features",
      subhead:
        "Check out the admin/backend features of our equity crowdfunding platform script.",
      demos: [
        {
          title: "Easy to Use",
          sub: "Easy to use admin panel with all features you need as platform admin.",
          img: dem16,
        },
        {
          title: "Campaign Management",
          sub: "Campaign management module for admin to manage all campaign from one place.",
          img: dem17,
        },
        {
          title: "Social Media",
          sub: "Social media integration with different apis like social sharing, social login, social invite.",
          img: dem18,
        },
        {
          title: "Payment Gateway",
          sub: "Secure and multiple payment gateway integration i.e Paypal adaptive, Stripe, Wallet & Manual Bank Wire.",
          img: dem19,
        },
        {
          title: "CMS",
          sub: "Complete cms module to manage pages and content for website.",
          img: dem20,
        },
        {
          title: "User Management",
          sub: "Easy user management module to add/edit/delete users from backend.",
          img: dem21,
        },
        {
          title: "Google Analytics",
          sub: "Integration of Google analytic to track traffic and nature of users.",
          img: dem22,
        },
        {
          title: "Contract Management",
          sub: "Complete contract management system to manage dynamic contract content.",
          img: dem23,
        },
        {
          title: "Dynamic Service Fees",
          sub: "Easily edit and configure percentage fees admin wants to earn from each investments on each campaign.",
          img: dem24,
        },
        {
          title: "Investment Management",
          sub: "Easy and complete investment management module to receive, confirm and manage investments from investors.",
          img: dem25,
        },
        {
          title: "Campaign Feedback",
          sub: "Complete campaign feedback module to provide feedback and communicate with campaign owner to launch campaign.",
          img: dem26,
        },
        {
          title: "Deal Management",
          sub: "Easy deal management module to enable/disable and edit parameters of equity, debt, profit sharing and convertible equity.",
          img: dem27,
        },
        {
          title: "Accreditation Process",
          sub: "Admin ability to manage investors with their accreditation status manually/via api.",
          img: dem28,
        },
        {
          title: "Dropdown Management",
          sub: "Admin ability to manage prefilled data represented as dropdown options from backend.",
          img: dem29,
        },
      ],
    },
    {
      heading: "Campaign Owner Features",
      subhead:
        "Check out the campaign owner's features of our equity crowdfunding platform software.",
      demos: [
        {
          title: "Unlimited Campaigns",
          sub: "User ability to create unlimited campaigns. Campaigns will go live only after admin approval",
          img: dem30,
        },
        {
          title: "Payment Gateway",
          sub: "User ability to choose and verify his/her payment gateway/bank account to get funds in the end of campaign.",
          img: dem31,
        },
        {
          title: "Multi Currency",
          sub: "User ability to create campaign in currency supported by country he/she living in.",
          img: dem32,
        },
        {
          title: "Campaign Dashboard",
          sub: "Campaign dashboard with all controllers required to manage campaign with easy to use interface.",
          img: dem33,
        },
        {
          title: "Google Analytic",
          sub: "Ability to integrate Google analytic code in campaign to keep track of nature and geo location of visitors.",
          img: dem34,
        },
        {
          title: "Secure Encryption",
          sub: "Password is stored with MD5 encryption so no one can have access to password.",
          img: dem35,
        },
        {
          title: "Perk Management",
          sub: "Perk management system to enhance campaign to attract more investors.",
          img: dem36,
        },
        {
          title: "Secure Platform",
          sub: "Secure platform to upload personal information, documents and other profile information which will be visible to admin only.",
          img: dem37,
        },
        {
          title: "Campaign Updates",
          sub: "User ability to announce campaign updates with easy to use option.",
          img: dem38,
        },
        {
          title: "Team Management",
          sub: "User ability to manage team with ability to give appropriate title/designation and editor permissions.",
          img: dem39,
        },
        {
          title: "Get Social",
          sub: "User ability to share campaign over social networking website easily to get maximum social attention.",
          img: dem40,
        },
        {
          title: "Embed Code",
          sub: "User ability to copy and paste campaign code to embed on his/her website or blog.",
          img: dem41,
        },
        {
          title: "Company URL",
          sub: "User ability to choose unique and dedicated company URL on platform to improve campaign branding.",
          img: dem42,
        },
        {
          title: "Multiple Company",
          sub: "User ability to create multiple companies under one account.",
          img: dem43,
        },
        {
          title: "Deal Documents",
          sub: "User ability to upload, manage private and public documents in form of doc, xls, ppt, pdf and so on.",
          img: dem44,
        },
        {
          title: "Company Profile",
          sub: "User ability to manage complete company profile with all required information and social media profiles.",
          img: dem45,
        },
        {
          title: "Deal Highlights",
          sub: "User ability to display highlighted features of his/her campaign.",
          img: dem46,
        },
        {
          title: "Cover Photo/Video",
          sub: "User ability to add cover photo or video for campaign.",
          img: dem47,
        },
        {
          title: "Easy to Use",
          sub: "Easy to use admin panel with all features you need as platform admin.",
          img: dem48,
        },
        {
          title: "Current Investors",
          sub: "User ability to highlight current investors of campaign.",
          img: dem49,
        },
        {
          title: "Previous Funding",
          sub: "User ability to highlight previous fundings of campaign.",
          img: dem50,
        },
        {
          title: "Elevator Pitch",
          sub: "User ability to draft full details of his/her campaign.",
          img: dem51,
        },
        {
          title: "Multiple Deals",
          sub: "User ability to choose best deal for his/her campaign i.e equity, debt, profit sharing and convertible equity.",
          img: dem52,
        },

        {
          title: "Censor Comments",
          sub: "User ability to moderate, approve, deny comments manually before they go live on his/her campaign page.",
          img: dem53,
        },
      ],
    },
  ];

  return (
    <div className="demo-feature">
      <Container>
        {demoCard.map((item, index) => {
          return <DemoFeatureCard key={index} data={item} />;
        })}
      </Container>
    </div>
  );
};

export default DemoFeatures;
