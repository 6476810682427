import Card from 'components/card/Card'
import MiniCard from 'components/mini card/MiniCard'
import { SecondContent } from 'components/second content'
import { MainSlider } from 'components/section'
import React from 'react'

const Home = () => {
  return (
    <div>
<MainSlider/>
<MiniCard/>
<SecondContent/>
<Card/>
    </div>
  )
}

export default Home