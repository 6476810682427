import Home from "pages/Home";
import { Route, Routes } from "react-router-dom";
import PricePage from "pages/PricePage";
import { ContactPage, WorkPage } from "pages";
import Realestate from "pages/Realestate";
import Equity from "pages/Equity";
import Reward from "pages/Reward";
import Donation from "pages/Donation";
import Lending from "pages/Lending";
import Employee from "pages/Employee";
import Energy from "pages/Energy";
import Faqs from "pages/Faqs";
import PaymentGateway from "pages/PaymentGateway";
import Request from "pages/Request";
import ProductPage from "pages/ProductPage";
import Terms from "pages/Terms";
import Policy from "pages/Policy";

const RoutePath = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/realestate/:type" element={<Realestate />} />
        <Route path="/equity/:type" element={<Equity />} />
        <Route path="/reward/:type" element={<Reward />} />
        <Route path="/donation/:type" element={<Donation />} />
        <Route path="/lending" element={<Lending />} />
        <Route path="/employee" element={<Employee />} />
        <Route path="/energy" element={<Energy />} />
        <Route path="/payment" element={<PaymentGateway />} />
        <Route path="/request" element={<Request />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policy />} />
      </Routes>
    </>
  );
};

export default RoutePath;
