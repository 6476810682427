import React from "react";
// import Banner from "components/core/Banner";
import ProductBanner from "./ProductBanner";

const ProductPage = () => {
  return (
    <div className="product-page">
      <div className="product-page-inner">
        {/* <Banner
          title={
            <>
              <p className="hero hero2-prod-page-col">
                Equity
                <span className="crowd-div-realestate"> Crowdfunding </span>
                Software
              </p>
            </>
          }
          title2={
            <>
              <p className="hero hero2-prod-page-col">
                or
                <span className="crowd-div-realestate">
                  {" "}
                  Crowd Investing{" "}
                </span>{" "}
                Script
              </p>
            </>
          }
          subtitle={
            <>
              <p className="real-est-sub-main prod-sub-col">
                Leverage our equity crowdfunding software, have it customised
                for your needs and grow your business.
              </p>
            </>
          }
          img="https://d1suf988f4fj5v.cloudfront.net/images/equity-banner-img.png"
          btn1={
            <>
              <button className="prod-btn">Buy Now</button>
            </>
          }
          btn2={
            <>
              <button className="prod-btn">Check Features</button>
            </>
          }
        /> */}
      </div>
      <ProductBanner />
    </div>
  );
};

export default ProductPage;
