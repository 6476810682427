import { Container, Grid } from "@mui/material";
import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const SocialMedia = ({ title, desc, title2, desc2 }) => {
  return (
    <div className="social-media">
      <div className="social-med-in">
        <Container>
          <div className="soc-upper-div">
            <div className="soc-top-sec">
              <div className="soc-top-sec-cont">
                <p className="soc-top-text">
                  CUSTOMER LOGIN WITH SOCIAL MEDIA ACCOUNTS
                </p>
              </div>
            </div>
            <div className="soc-upper-img-div">
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/social-login/login-facebook.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/social-login/login-twitter.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/social-login/login-linkedin.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/social-login/login-google.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/social-login/login-instagram.png"
                  alt="fb"
                />
              </div>
            </div>
          </div>
        </Container>
        <div className="soc-below-div">
          <Container>
            <div className="soc-blw-div-title">
              <p className="soc-blw-div-title-main">{title}</p>
            </div>
            {desc}
          </Container>
        </div>
        <Container>
          <div className="soc-upper-div2">
            <div className="soc-top-sec">
              <div className="soc-top-sec-cont">
                <p className="soc-top-text">
                  CUSTOMER LOGIN WITH SOCIAL MEDIA ACCOUNTS
                </p>
              </div>
            </div>
            <div className="soc-upper-img-div">
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/constant_contact.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/mailchimp.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/campaignmonitor.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/zurmo.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/dotmailer.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/madmimi.png"
                  alt="fb"
                />
              </div>
              <div className="soc-upper-img">
                <img
                  src="https://d1suf988f4fj5v.cloudfront.net/images/newsletter/referralcandy.png"
                  alt="fb"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="soc-med-sec2">
          <div className="soc-blw-div-title">
            <p className="soc-blw-div-title-main">{title2}</p>
          </div>
          {desc2}
          <Grid
            container
            direction="row"
            spacing={5}
            style={{ paddingTop: "2rem" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="soc-med-card">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 85, height: 85, mr: 3 }}>
                      <img
                        style={{ backgroundColor: "#479CC4" }}
                        src="https://d1suf988f4fj5v.cloudfront.net/images/icon-flow.png"
                        alt="dm"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      color: "white",
                    }}
                    secondaryTypographyProps={{ color: "white" }}
                    primary="WORK FLOW"
                    secondary="Click here to enlarge view"
                  />
                </ListItem>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="soc-med-card2">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 85, height: 85, mr: 3 }}>
                      <img
                        style={{ backgroundColor: "#3C3C3C" }}
                        src="https://d1suf988f4fj5v.cloudfront.net/images/icon-flow.png"
                        alt="dm"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      color: "white",
                    }}
                    secondaryTypographyProps={{ color: "white" }}
                    primary="Investment Flow"
                    secondary="Click here to enlarge view"
                  />
                </ListItem>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="soc-med-sec3">
        <div className="social-med-in2">
          <Container>
            <div className="soc-upper-div">
              <div className="soc-top-sec">
                <div className="soc-top-sec-cont">
                  <p className="soc-top-text">PAYMENT GATEWAYS SUPPORTED</p>
                </div>
              </div>
              <div className="soc-upper-img-div">
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-paypal.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-stripe.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-wallet.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-2checkout.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-finxera.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-sagepay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-skrill.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-wepay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-bluepay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-braintree.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-chronopay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-fastcharge.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-firstdata.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-mangopay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-paynova.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-paysimple.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-securepay.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/payment/payment-sofort.png"
                    alt="fb"
                  />
                </div>
              </div>
            </div>
          </Container>
          <div className="soc-below-div">
            <Container>
              <div className="soc-blw-div-title">
                <p className="soc-blw-div-title-main">
                  MONETIZE YOUR CROWD INVESTING PLATFORM
                </p>
              </div>
              <div className="soc-blw-div-sub">
                <p className="soc-blw-div-sub-main">
                  There is no membership fees required for businesses or
                  companies to list their equity offerings on an Equity
                  Crowdfunding platform powered by our Equity investing script.
                  The site admin can earn revenue by keeping a share from the
                  investments received by these companies through the web
                  portal.
                </p>
              </div>
              <div className="soc-med-sec3-img-div">
                <img
                  className="soc-med-sec3-img-div-main"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/revenue-banner.png"
                  alt=""
                />
              </div>
            </Container>
          </div>
          <Container>
            <div className="soc-upper-div2">
              <div className="soc-top-sec">
                <div className="soc-top-sec-cont">
                  <p className="soc-top-text">
                    COMPLETE SOCIAL MEDIA INTEGRATIONS
                  </p>
                </div>
              </div>
              <div className="soc-upper-img-div-sec3">
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/facebook.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/twitter.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/linkedin.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/google.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/instagram.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/pinterest.png"
                    alt="fb"
                  />
                </div>
                <div className="soc-upper-img">
                  <img
                    src="https://d1suf988f4fj5v.cloudfront.net/images/social-share/whatsapp.png"
                    alt="fb"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
