import { Container } from "@mui/material";
import React from "react";

const ReqBanner = () => {
  return (
    <div className="req-banner">
      <div className="req-ban-inner">
        <Container>
          <div className="req-ban-cont">
            <div className="req-ban-title">
              <p className="req-ban-title-main">Request a Demo</p>
            </div>
            <div className="req-ban-sub">
              <p className="req-ban-sub-main">
                We would love to hear from you. Please fill the form below and
                we will get back to you in a jiffy
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ReqBanner;
