import { Container, Grid } from "@mui/material";
import { work1, work2, work3, work4 } from "assets";
import React from "react";

const WorkCards = [
  {
    img: work1,
    sub: "Equity CrowdFunding",
  },
  {
    img: work2,
    sub: "Donation CrowdFunding",
  },
  {
    img: work3,
    sub: "Real Estate CrowdFunding",
  },
  {
    img: work4,
    sub: "Reward CrowdFunding",
  },
  // {
  //   img: "https://d1suf988f4fj5v.cloudfront.net/images/our-works/thumbs/Fundraisingscript-18.jpg",
  //   sub: "Crowdfunding Template 05",
  // },
  // {
  //   img: "https://d1suf988f4fj5v.cloudfront.net/images/our-works/thumbs/Fundraisingscript-20.jpg",
  //   sub: "Crowdfunding Template 06",
  // },
  // {
  //   img: "https://d1suf988f4fj5v.cloudfront.net/images/our-works/thumbs/Fundraisingscript-22.jpg",
  //   sub: "Crowdfunding Template 07",
  // },
  // {
  //   img: "https://d1suf988f4fj5v.cloudfront.net/images/our-works/thumbs/Fundraisingscript-23.jpg",
  //   sub: "Crowdfunding Template 08",
  // },
];

const WorkCard = () => {
  return (
    <div className="work-card-div">
      <Container className="main-blog-container d-flex g-1">
        <Grid container spacing={6}>
          {WorkCards.map((item, index) => {
            return (
              <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                <div className="work-card">
                  <div className="work-card-img">
                    <img
                      className="work-card-img-main"
                      src={item.img}
                      alt="card-img"
                    />
                  </div>
                  <div className="work-card-sub">
                    <p className="work-card-sub-main">{item.sub}</p>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default WorkCard;
