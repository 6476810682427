import { Container } from "@mui/material";
import React from "react";

const PriceCard = () => {
  return (
    <div className="price-card-div">
      <Container>
        <div className="price-card-inner">
          <div className="price-card-heading">
            <p className="price-card-heading-main">
              Reward based crowdfunding platform is designed and developed
              specially to meet your requirements and fulfill your dream of a
              successful Reward based crowd funding platform. Start your reward
              crowdfunding platform where project owners can post their amazing
              project and pledgers can pledge to get rewards.
            </p>
          </div>
          {/* <div className="price-card-button-div">
          <div className="price-card-button-inner">
            <div className="price-card-button-left">
              <button className="pc-btn-left-main">
                Self-Hosted Packages
                <span className="pc-icon-btn">
                  <i class="fa-solid fa-question"></i>
                </span>
              </button>
            </div>
            <div className="price-card-button-right">
              <button className="pc-btn-right-main">
                Hosted Packages
                <span className="pc-icon-btn">
                  <i class="fa-solid fa-question"></i>
                </span>
              </button>
            </div>
          </div>
        </div> */}
        </div>
      </Container>
    </div>
  );
};

export default PriceCard;
