import { Container } from "@mui/material";
import React from "react";

const server = [
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/amz.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/arvixe.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/bluehost.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/codero.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/constant-hosting.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/direct-admin.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/exmasters.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/greengeeks.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/hivelocity.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/hostforweb.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/host-gator.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/hosting-source.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/hub.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/inmotion.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/iPage.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/irish-domains.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/justhost.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/myhosting.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/R1Soft.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/singlehop.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/superb-internet.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/webhostingpad.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/whm.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/constant-hosting.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/hosting/znetlive.png",
  },
];
const api = [
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/accredify.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/accreditedvalidation.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/accuratebackground.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/crowdbouncer.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/crowdsignature.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/docdep.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/docuSign.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/equidam.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/miicard.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/rightsignature.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/verifyinvestor.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/veritax.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/vstocktransfer.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/worthworm.png",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/others/sum-sub.png",
  },
];

const ServerApi = () => {
  return (
    <div className="server-div">
      <div className="server-div-inner">
        <Container>
          <div className="server-api-title">
            <p className="server-api-title-main">LOT’S OF SERVER OPTIONS</p>
          </div>
          <div className="server-api-img-div">
            {server.map((item, index) => (
              <div className="server-api-img-item" item={index}>
                <img src={item.img} alt="ser" />
              </div>
            ))}
          </div>
          <div className="server-api-title">
            <p className="server-api-title-main2">LOT’S OF SERVER OPTIONS</p>
          </div>
          <div className="server-api-img-div">
            {api.map((item, index) => (
              <div className="server-api-img-item" item={index}>
                <img src={item.img} alt="ser" />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ServerApi;
