import React from "react";
import EnergyBanner from "./EnergyBanner";
import EnergyContact from "./EnergyContact";
import EnergyHighlight from "./EnergyHighlight";
import EnergyIntro from "./EnergyIntro";
import EnergyWorks from "./EnergyWorks";

const Energy = () => {
  return (
    <div>
      <div className="energy-div"></div>
      <EnergyBanner />
      <EnergyIntro />
      <EnergyHighlight />
      <EnergyContact />
      <EnergyWorks />
    </div>
  );
};

export default Energy;
