import { Button, Container, Grid, Typography } from "@mui/material";
import { gift_box_icon, quality_icon, support_icon } from "assets";
import React from "react";
import { NavLink } from "react-router-dom";

const miniCardArr = [
  {
    icon: gift_box_icon,
    title: "What We Offer",
    desc: "FundraisingScript offers a White Label Crowdfunding Software. Our crowdfunding software is fully customizable, scalable as per your needs to help grow your business exponentially help skyrocket your revenue.",
    btn: (
      <NavLink to="/price">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          Check Pricing
        </Button>
      </NavLink>
    ),
  },
  {
    icon: support_icon,
    title: "Our Support",
    desc: "Full technical and dedicated support, training for our crowdfunding software. Explore custom crowdfunding platform ideas, tips and many more via our blog.",
    btn: (
      <a
        href="https://blog.searchingyard.com/"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          Our blog
        </Button>
      </a>
    ),
  },
  {
    icon: quality_icon,
    title: "Feature And Quality",
    desc: "Fully featured crowdfunding software designed and developed with modern and fully responsive design and compatible with almost all platforms and devices.",
    btn: (
      <NavLink to="/work">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          Our Works
        </Button>
      </NavLink>
    ),
  },
];
const MiniCard = () => {
  return (
    <Container className="mini-card-container">
      <Grid container spacing={3} justifyContent="center">
        {miniCardArr.map((curElm, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <div className="main-mini-card-div d-flex flex-column justify-btw align-cnt">
                <div className="mini-card-img d-flex justify-cnt align-cnt pb-1">
                  <img
                    src={curElm.icon}
                    alt="gift-box"
                    className="mini-img-icon"
                  />
                </div>
                <Typography variant="h6" className="main-text-clr">
                  {curElm.title}
                </Typography>
                <div className="mini-card-title"></div>

                <div className="mini-card-text ">
                  <Typography variant="body1">{curElm.desc}</Typography>
                </div>
                <div className="mini-card-btn-div d-flex justify-cnt align-cnt">
                  {curElm.btn}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default MiniCard;
