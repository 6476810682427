import React from "react";
import FaqsBanner from "./FaqsBanner";

const Faqs = () => {
  return (
    <div>
      <FaqsBanner />
    </div>
  );
};

export default Faqs;
