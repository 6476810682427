import React, { useEffect, useState } from "react";
import Banner from "components/core/Banner";
import Demopage from "./Demopage";
import { banner4, demos4 } from "assets";
import DemoTabs from "components/core/DemoTabs";
import DemoOverview from "./DemoOverview";
import { NavLink, useParams } from "react-router-dom";

const Donation = () => {
  const [value, setValue] = useState(0);
  const { type } = useParams();
  useEffect(() => {
    switch (type) {
      case "demo":
        setValue(3);
        break;
      case "pricing":
        setValue(2);
        break;
      case "features":
        setValue(1);
        break;

      default:
        setValue(0);
    }
    return () => {
      setValue(0);
    };
  }, [type]);
  return (
    <>
      <div className="donation-div">
        <div className="donation-inner">
          <Banner
            isIntegration={true}
            title={
              <>
                <p className="hero">
                  Donation
                  <span className="crowd-div-realestate"> Crowdfunding </span>
                  Software
                </p>
              </>
            }
            title2={
              <>
                <p className="hero">
                  or
                  <span className="crowd-div-realestate">
                    {" "}
                    Donation Fundraising{" "}
                  </span>
                  Script
                </p>
              </>
            }
            subtitle={
              <>
                <p className="real-est-sub-main">
                  Automate donation crowdfunding operations and manage
                  individual and corporate fundraising with Fundraisingscript.
                  Digitize your offline donation crowdfunding business by
                  launching a private donation crowdfunding platform.
                </p>
              </>
            }
            img={banner4}
            btn1={
              <>
                <NavLink to="/donation/pricing">
                  <button className="real-est-btn1-main">View Prices</button>
                </NavLink>
              </>
            }
            btn2={
              <>
                <NavLink to="/donation/features">
                  <button className="real-est-btn2-main">Check Features</button>
                </NavLink>
              </>
            }
          />
        </div>
      </div>
      <DemoTabs
        value={value}
        setValue={setValue}
        overview={
          <>
            <DemoOverview
              img={demos4}
              contents={[
                {
                  id: "1",
                  title: "INTRODUCTION TO DONATION CROWDFUNDING",
                  desc: (
                    <>
                      Unlike other crowdfunding schemes, donation-based
                      crowdfunding is a platform where people donate money
                      through the platform for a common collaborative goal
                      without expecting anything in return. Few donation-based
                      crowdfunding platforms may also offer some products, perks
                      or rewards as “Thank You” for their donation but there’s
                      no return on investment involved in this type of fund
                      raising.
                    </>
                  ),
                },
                {
                  id: "2",
                  title: "WHAT IS DONATION CROWDFUNDING SCRIPT?",
                  desc: (
                    <>
                      Donation Crowdfunding software enables you to set up an
                      online portal or a personal crowdfunding platform to
                      receive donations from people, all over the globe or a
                      specific region, depending upon what you choose, for a
                      good cause, through our online Crowdfunding portal
                      approach or under the{" "}
                      <span className="demo-cont-span">
                        Crowdfunding DIY Approach
                      </span>
                      . Such donation-based Crowdfunding software is widely used
                      by NGOs to encourage people to donate money towards a
                      common cause that would help the needy or the betterment
                      of our own environment.
                    </>
                  ),
                },
              ]}
              socialMediaProps={{
                title:
                  "HOW DOES THE DONATION BASED CROWDFUNDING SOFTWARE HELP YOU?",
                desc: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        If you are representing an NGO or looking forward to
                        start your own white label donation-based crowdfunding
                        for a cause you believe in, then our PHP Crowdfunding
                        software is an ultimate choice for you. Our software
                        will help you to set up a donation-based fund collection
                        portal that can be used to post new projects related to
                        different causes. These campaigns may pertain to any
                        niche, region, currency or language you choose. Once a
                        campaign is live, it will be able to receive donations
                        from volunteers from among the general public who are
                        interested in contributing towards the cause.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Our experts have thoroughly analyzed the market in order
                        to develop this amazing piece of application ideal for
                        setting up a donation-based funding platform. Each
                        factor essential to run a donation program online is
                        addressed in order to offer an easy, smooth and reliable
                        experience for the people making donations. It also
                        allows you to set up multiple payment options as per
                        your requirements to help you to receive donations from
                        the every nook and corner of the world.
                      </p>
                    </div>
                  </>
                ),
                title2: "HOW DOES THE REWARD CROWDFUNDING SCRIPT WORK?",
                desc2: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Once you install our donation based crowdfunding script
                        on your website or server, Individuals, Companies or
                        Organizations will be able to register and list their
                        projects, campaigns or causes in order to invite and
                        accept donations online through crowdfunding. All the
                        projects listed on the website will have to be reviewed
                        and approved by the site admin before going live.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Visitors on the website can surf through different
                        causes or projects listed by the Project owner and
                        choose any one or multiple projects to volunteer
                        donations through different payment options enabled by
                        the site admin.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Visitors on the website can surf through different
                        causes or projects listed by the Project owner and
                        choose one or multiple projects to volunteer donations
                        through different payment options enabled by the site
                        admin.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        All donations received through this Crowdfunding
                        platform will be split into two parts, where one part
                        goes to the Project Owner’s account as donations and
                        another part is kept by the site admin as fees for the
                        service offered by means of the Crowdfunding platform.
                        More details can be found on the workflow chart below.
                      </p>
                    </div>
                  </>
                ),
              }}
            />
          </>
        }
        demoTab={
          <Demopage
            isGooglePlay={true}
            info={
              <>
                Donation based crowd funding platform is designed and developed
                specially to meet your requirements and fulfill your dream of a
                successful Donation based crowdfunding platform. Start your
                donation crowdfunding platform where fundraisers can post their
                social cause project and donors can donate to be a part of good
                cause.
              </>
            }
            cardInfo={[
              {
                title: "Default Theme",
                subtitle: (
                  <p className="demo-card-para-main">
                    <span className="demo-card-para-ref"></span>.
                  </p>
                ),
                img: demos4,
                btn: (
                  <a
                    href="http://donation-crowdfunding.yardfundraising.searchingyard.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
              {
                title: "Classic Theme",
                subtitle: (
                  <p className="demo-card-para-main">
                    <span className="demo-card-para-ref"></span>.
                  </p>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-donation-classic.png",
                btn: <button className="demo-card-btn-main">Try Demo</button>,
              },
              {
                title: "Admin Demo",
                subtitle: (
                  <div className="demo-card-para2">
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Username: </span>{" "}
                        admin
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Password:</span>{" "}
                        admin
                      </p>
                    </div>
                  </div>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-donation-a.png",
                btn: (
                  <a
                    href="https://yardfundrasing-admin.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
            ]}
          />
        }
      />
      {/* <Demopage
        isGooglePlay={true}
        info={
          <>
            Donation based crowd funding platform is designed and developed
            specially to meet your requirements and fulfill your dream of a
            successful Donation based crowdfunding platform. Start your donation
            crowdfunding platform where fundraisers can post their social cause
            project and donors can donate to be a part of good cause.
          </>
        }
        cardInfo={[
          {
            title: "Default Theme",
            subtitle: (
              <p className="demo-card-para-main">
                <span className="demo-card-para-ref"></span>.
              </p>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-donation-f.png",
            btn: (
              <a
                href="https://donation-crowdfunding.yardfundrasing.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="demo-card-btn-main">Try Demo</button>
              </a>
            ),
          },
          {
            title: "Classic Theme",
            subtitle: (
              <p className="demo-card-para-main">
                <span className="demo-card-para-ref"></span>.
              </p>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-donation-classic.png",
            btn: <button className="demo-card-btn-main">Try Demo</button>,
          },
          {
            title: "Admin Demo",
            subtitle: (
              <div className="demo-card-para2">
                <div>
                  <p>
                    <span className="demo-card-admin-text">Username: </span>{" "}
                    admin
                  </p>
                </div>
                <div>
                  <p>
                    <span className="demo-card-admin-text">Password:</span>{" "}
                    admin
                  </p>
                </div>
              </div>
            ),
            img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-donation-a.png",
            btn: <button className="demo-card-btn-main">Try Demo</button>,
          },
        ]}
      /> */}
    </>
  );
};

export default Donation;
