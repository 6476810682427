import React from "react";

const LendCard = ({ img, title, sub, isPremium }) => {
  return (
    <div className="lend-feature-card">
      <div className="lend-feature-card-icon">
        <img className="lend-card-img" src={img} alt="" />
      </div>
      <div className="lend-card-title">
        <p className="lend-card-title-main">{title}</p>
        {isPremium && <span className="lend-card-btn">Premium</span>}
      </div>
      <div className="lend-card-sub">
        <p className="lend-card-sub-main">{sub}</p>
      </div>
    </div>
  );
};

export default LendCard;
