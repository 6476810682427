import { Container, Divider, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import { main_logo, appleIcon, playIcon } from "assets";
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="main-footer-bg">
      <Container className="footer-container d-flex justify-btw w-100">
        <div className="left-footer-div">
          <div className="footer-img-div">
            <img src={main_logo} alt="logo" />
          </div>

          <Typography
            variant="body1"
            className="font-size-0_9 clr-light pt-1 pb-1 footer-about-text"
          >
            All-in-one crowdfunding software development solution. We understand
            your needs, hence we don't just offer ready to use crowdfunding
            software but provide custom crowdfunding software development
            service, so you get your customized crowdfunding solution to
            skyrocket your revenue.
          </Typography>

          <div className="footer-social-div d-flex align-cnt">
            <a
              href="https://www.linkedin.com/company/searchingyardindia/mycompany/verification/"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton className="icon-linkedin mr-1">
                <LinkedInIcon className="clr-white " />
              </IconButton>
            </a>
            <a
              href="https://www.instagram.com/SearchingYard/"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton className="icon-insta mr-1">
                <InstagramIcon className="clr-white " />
              </IconButton>
            </a>
            <a
              href="https://www.facebook.com/searchingyard/"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton className="icon-fb mr-1">
                <FacebookIcon className="clr-white" />
              </IconButton>
            </a>
            <a
              href="https://twitter.com/YardSearching"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton className="icon-twitter ">
                <TwitterIcon className="clr-white" />
              </IconButton>
            </a>
          </div>
          <div className="footer-ratings ">
            <Typography variant="body1" className="pt-1 pb-1 clr-light">
              See our glowing reviews on
            </Typography>
            <div className="d-flex align-cnt g-1">
              <a
                href="https://play.google.com/store/apps/developer?id=SearchingYard+Software+Private+Limited"
                target="_blank"
                rel="noreferrer"
              >
                <img src={playIcon} className="play-icon-img" alt="google" />
              </a>
              <a
                href="https://apps.apple.com/us/developer/ashutosh-mohapatra/id1585081396"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleIcon} className="play-icon-img" alt="apple" />
              </a>
            </div>
          </div>
        </div>

        <div className="right-footer-div">
          <Typography
            variant="body1"
            className="footer-quick-links new-text-clr pb-1"
          >
            QUICK LINKS
          </Typography>
          <div className="d-flex g-2">
            <div className="d-flex flex-column g-1 font-size-0_9 clr-light">
              <li className="footer-link-li">
                <NavLink to="/request">Demo</NavLink>
              </li>
              <li className="footer-link-li">
                <NavLink to="/price">Pricing Details</NavLink>
              </li>

              <li className="footer-link-li">
                <a
                  href="https://blog.searchingyard.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
              <li className="footer-link-li">
                <NavLink to="/faqs">FAQs</NavLink>
              </li>
              <li className="footer-link-li">
                <NavLink to="/energy">Renewable Energy CrowdFunding</NavLink>
              </li>
            </div>
            <div className="d-flex flex-column g-1 font-size-0_9 clr-light">
              <li className="footer-link-li">
                <a
                  href="https://searchingyard.com/term-and-condition.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </li>
              <li className="footer-link-li">
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
              <li className="footer-link-li">
                <a
                  href="https://searchingyard.com/privacy-policy.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>

              <li className="footer-link-li">
                <NavLink to="/work">Our Works</NavLink>
              </li>
            </div>
          </div>
        </div>

        <div className="footer-solution-div">
          <Typography
            variant="body1"
            className="footer-solution new-text-clr pb-1"
          >
            OUR PRODUCTS
          </Typography>
          <div className="d-flex flex-column g-1 font-size-0_9 clr-light">
            <li className="footer-link-li">
              <a
                href="https://reward.yardfundraising.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                Reward Crowdfunding
              </a>
            </li>
            <li className="footer-link-li">
              <a
                href="http://donation-crowdfunding.yardfundraising.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                Donation Crowdfunding
              </a>
            </li>
            <li className="footer-link-li">
              <a
                href="https://equity.yardfundraising.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                Equity Crowdfunding
              </a>
            </li>
            <li className="footer-link-li">
              <a
                href="https://realestate.yardfundraising.searchingyard.com"
                target="_blank"
                rel="noreferrer"
              >
                Real Estate Crowdfunding
              </a>
            </li>
            <li className="footer-link-li">
              <NavLink to="/lending">P2P Lending </NavLink>
            </li>
            <li className="footer-link-li">
              <NavLink to="/employee">Employee Crowdfunding </NavLink>
            </li>
          </div>
        </div>
      </Container>
      <Divider className="footer-divider " />

      <Container className="equity-cont">
        <div className="foot-low-bar">
          <div className="foot-low-inner">
            <div className="foot-low-left">
              <p className="footer-content">
                © {new Date(Date.now()).getFullYear()},{" "}
                <a
                  className="footer-copyright-text"
                  href="https://searchingyard.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SearchingYard Software Pvt. Ltd. All Rights Reserved.
                </a>{" "}
                A Unit of SearchingYard.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
