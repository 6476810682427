import React from "react";
import WorkBanner from "./WorkBanner";
import WorkCard from "./WorkCard";

const WorkPage = () => {
  return (
    <div>
      <WorkBanner />
      <WorkCard />
    </div>
  );
};

export default WorkPage;
