import Collapse from "@mui/material/Collapse";

import { Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import { main_logo } from "assets";
import NestedList from "./NestedList";
import { useState } from "react";

export default function ListNav() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Container className="list-nav-container-bg">
      <div className="list-nav-main-div">
        <NavLink to="/">
          <img src={main_logo} alt="logo" />
        </NavLink>
        <span onClick={handleClick}>
          {open ? <CloseIcon style={{ color: "red" }} /> : <MenuIcon />}
        </span>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <NestedList />
      </Collapse>
    </Container>
  );
}
