import { banner1, demos1 } from "assets";
import Banner from "components/core/Banner";
import DemoTabs from "components/core/DemoTabs";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import DemoOverview from "./DemoOverview";
import Demopage from "./Demopage";

const Realestate = () => {
  const [value, setValue] = useState(0);
  const { type } = useParams();
  useEffect(() => {
    switch (type) {
      case "demo":
        setValue(3);
        break;
      case "pricing":
        setValue(2);
        break;
      case "features":
        setValue(1);
        break;

      default:
        setValue(0);
    }
    return () => {
      setValue(0);
    };
  }, [type]);
  return (
    <>
      <div className="real-estate-div">
        <div className="real-estate-inner">
          <Banner
            isIntegration={true}
            title={
              <>
                <p className="hero">
                  Real Estate{" "}
                  <span className="crowd-div-realestate">Crowdfunding</span>
                </p>
              </>
            }
            title2={
              <>
                <p className="hero">
                  Software or{" "}
                  <span className="crowd-div-realestate">Investment</span>{" "}
                  Script
                </p>
              </>
            }
            subtitle={
              <>
                <p className="real-est-sub-main">
                  Create a buy-to-sell or buy-to-rent property crowdfunding
                  platform for real estate association. Fundraisingscript
                  provides a customizable real estate crowdfunding software for
                  different stages of your business.
                </p>
              </>
            }
            btn1={
              <>
                <NavLink to="/realestate/pricing">
                  <button className="real-est-btn1-main">View Prices</button>
                </NavLink>
              </>
            }
            btn2={
              <>
                <NavLink to="/realestate/features">
                  <button className="real-est-btn2-main">Check Features</button>
                </NavLink>
              </>
            }
            img={banner1}
          />

          {/* <div className="real-estate-hero">
            <div className="real-estate-hero-left">
              <div className="real-estate-hero-title">
                <p className="hero">
                  Real Estate{" "}
                  <span className="crowd-div-realestate">Crowdfunding</span>
                </p>
              </div>
              <div className="real-estate-hero-title2">
                <p className="hero">
                  Software or{" "}
                  <span className="crowd-div-realestate">Investment</span>{" "}
                  Script
                </p>
              </div>
              <div className="real-est-sub">
                <p className="real-est-sub-main">
                  Create a buy-to-sell or buy-to-rent property crowdfunding
                  platform for real estate association. Fundraisingscript
                  provides a customisable real estate crowdfunding software for
                  different stages of your business.
                </p>
              </div>
              <div className="real-est-buttons">
                <div className="real-est-btn1">
                  <button className="real-est-btn1-main">Buy Now</button>
                </div>
                <div className="real-est-bt2">
                  <button className="real-est-btn2-main">Check Features</button>
                </div>
              </div>
            </div>
            <div className="real-estate-hero-right">
              <img
                src="https://d1suf988f4fj5v.cloudfront.net/images/real-estate-banner-img.png"
                className="real-estate-lapi-img"
                alt="lapi"
              />
              <div className="real-est-hero-last">
                <div className="integrate-div">
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">15+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        Payment Gateway Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">10+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        Cryptocurrency Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">10+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        {" "}
                        KYC & AML Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">5+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        {" "}
                        E-Signature Integrations
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <DemoTabs
        value={value}
        setValue={setValue}
        overview={
          <>
            <DemoOverview
              img={demos1}
              contents={[
                {
                  id: "1",
                  title: "INTRODUCTION TO REAL ESTATE CROWDFUNDING",
                  desc: (
                    <>
                      Since the advent of crowdfunding schemes, there have been
                      numerous programs where people join to aid funds for
                      specific causes such as{" "}
                      <span className="demo-cont-span">donation, equity</span>,
                      rewards and lending. However, recently real estate
                      industry is also trying to aid its projects through an
                      online crowdfunding site. Although the minimum investment
                      is a bit higher compared to other schemes,{" "}
                      <span className="demo-cont-span">
                        real estate crowdfunding websites
                      </span>{" "}
                      are ideal for those who wish to invest their money for
                      greater returns in the coming years.
                    </>
                  ),
                },
                {
                  id: "2",
                  title: "WHAT IS REAL ESTATE CROWDFUNDING SOFTWARE?",
                  desc: (
                    <>
                      With the help of{" "}
                      <span className="demo-cont-span">
                        white label real estate crowdfunding software
                      </span>{" "}
                      or script, you can set up your own full-fledged
                      crowdfunding portal wherein people can list new commercial
                      or residential real estate deals and projects, and
                      investors from the public can review and opt to invest
                      directly through the online real estate crowdfunding
                      portal approach or under the{" "}
                      <span className="demo-cont-span">
                        Crowdfunding DIY Approach
                      </span>
                      . Being a booming market, Real estate crowdfunding deals
                      and projects are slowly gaining popularity among the
                      potential investors as it delivers a higher return on
                      crowdfunding investment compared to other types of
                      crowdfunding projects and deals.
                    </>
                  ),
                },
              ]}
              socialMediaProps={{
                title:
                  "HOW DOES THE WHITE LABEL REAL ESTATE CROWDFUNDING SCRIPT HELP YOU?",
                desc: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Fundraisingscript.com helps you with an ideal custom
                        built real estate crowdfunding software that offers a
                        one-click install to set up your white label
                        crowdfunding platform for real estate projects and
                        deals. You can then invite real estate developers to
                        list their projects and deals on your crowdfunding
                        website to invite funds or investments from common
                        people interested in investing in the real estate
                        project.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Our team comprises experts from the real estate industry
                        who know ins and outs of the market. This has helped us
                        to address all the possible scenarios and add advanced
                        features to the script. Yet the interface is user
                        friendly and extremely easy to use. You can either use
                        it for your own real estate project or set up a platform
                        for listing projects by others. Investment can be
                        invited from people all over the globe or from a
                        specific country or region. There are multiple payment
                        options in the program and high security measures are in
                        place to avoid any fraud or threat to investors.
                        Responsive design makes your platform accessible on
                        mobile phones and crowdfunding mobile app.
                      </p>
                    </div>
                  </>
                ),
                title2: "HOW DOES THE SCRIPT WORK?",
                desc2: (
                  <>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Our real estate funding script will help you to create a
                        website similar to popular real estate crowdfunding
                        sites like iFunding,Fundrise, Realty Mogul, and
                        Crowdstreet. It will be an attractive alternative real
                        estate crowdfunding platform. You can create a direct
                        clone of these sites and even customize your site
                        further to make it unique.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        This platform will allow Real Estate developers to
                        register and list their projects up for Crowdfunding.
                        However, all the projects submitted over the portal must
                        go through admin screening process and once the site
                        admin approves the project, it will go live and will be
                        open for funding from interested people.
                      </p>
                    </div>
                    <div className="soc-blw-div-sub">
                      <p className="soc-blw-div-sub-main">
                        Individuals and companies interested in the real estate
                        market can visit the portal, surf through different real
                        estate projects listed thereon and choose to invest in
                        one or multiple crowdfunding projects. All the funding
                        generated through investments will be split into two
                        parts – one part for the site admin and the other part
                        for the project owner. The percentage of share from the
                        overall funding will be set by the site admin. The part
                        going to the site admin is actually the fee for
                        providing the crowdfunding platform.
                      </p>
                    </div>
                  </>
                ),
              }}
            />
          </>
        }
        demoTab={
          <Demopage
            info={
              <>
                A real estate based crowdfunding platform is designed and
                developed especially to meet your requirements and fulfill your
                dream of a successful white label real estate based crowdfunding
                platform. Start your{" "}
                <span className="demo-para-link">
                  open source real estate crowdfunding platform
                </span>{" "}
                where businesses/company can post their real estate developments
                and investors can invest to receive equity/share or interest
                from company against their investments.
              </>
            }
            cardInfo={[
              {
                title: "Front End Demo",
                subtitle: (
                  <p className="demo-card-para-main">
                    <span className="demo-card-para-ref"></span>.
                  </p>
                ),
                img: demos1,
                btn: (
                  <a
                    href="https://realestate.yardfundraising.searchingyard.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
              {
                title: "Admin Demo",
                subtitle: (
                  <div className="demo-card-para2">
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Username: </span>{" "}
                        admin
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Password:</span>{" "}
                        Admin@123
                      </p>
                    </div>
                  </div>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-realestate-a.png",
                btn: (
                  <a
                    href="https://yardfundrasing-admin.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
            ]}
          />
        }
      />
      {/* */}
    </>
  );
};

export default Realestate;
