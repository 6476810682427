import React from "react";
import { main_logo } from "assets";
import { Container } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Payment from "./Payment";
import { Solution, NewSolution } from ".";
import { NavLink } from "react-router-dom";
import ListNav from "./ListNav";

const NavHeader = () => {
  return (
    <div className="nav-header-bg">
      <Container className="main-nav-header-container d-flex justify-btw align-cnt w-100 ">
        <div className="left-nav-header-div d-flex align-cnt justify-btw">
          <NavLink to="/">
            <img src={main_logo} alt="main-logo" />
          </NavLink>
        </div>
        <div className="right-nav-header-div d-flex justify-end align-cnt w-100">
          <ul className="list-style-none nav-header-ul w-100 d-flex justify-btw align-cnt">
            <li className="nav-header-solution nav-header-li d-flex align-cnt">
              <a href="/solution">Products</a>
              <span className="d-flex align-cnt">
                <ExpandMoreIcon className="expand-icon clr-light" />
              </span>
              <div className="solution-component-div d-none">
                <Solution />
              </div>
            </li>
            {/* <li className="hover-nav-header-integration nav-header-li d-flex align-cnt">
              <a href="/integration">Integration</a>
              <span className="d-flex align-cnt">
                <ExpandMoreIcon className="expand-icon clr-light" />
              </span>
              <div className="payment-component-div d-none">
                <Payment />
              </div>
            </li> */}
            <li className="nav-header-solution nav-header-li d-flex align-cnt">
              <a href="!#">Solution</a>
              <span className="d-flex align-cnt">
                <ExpandMoreIcon className="expand-icon clr-light" />
              </span>
              <div className="solution-component-div d-none">
                <NewSolution />
              </div>
            </li>
            {/* <li className="nav-header-li  pt-1 pb-1">
              <NavLink to="/price">Pricing</NavLink>
            </li> */}
            <li className="nav-header-li  pt-1 pb-1">
              <NavLink to="/faqs">FAQs</NavLink>
            </li>
            <li className="nav-header-li  pt-1 pb-1">
              <NavLink to="/work">Our Work</NavLink>
            </li>
            <li className="nav-header-li  pt-1 pb-1">
              <NavLink to="/contact">Contact Us</NavLink>
            </li>
            <li className="nav-header-li  pt-1 pb-1">
              <NavLink to="/request">
                <button className="nav-head-btn-main">Request A Demo</button>
              </NavLink>
            </li>
          </ul>
        </div>
      </Container>
      <ListNav />
    </div>
  );
};

export default NavHeader;
