import { Container, Grid } from "@mui/material";
import { demos2 } from "assets";
import ServerApi from "components/core/ServerApi";
import SocialHigh from "components/core/SocialHigh";
import SocialMedia from "components/core/SocialMedia";
import { Fragment } from "react";

const DemoOverview = ({
  img = demos2,
  contents = [
    {
      id: "1",
      title: "INTRODUCTION TO EQUITY CROWDFUNDING",
      desc: (
        <>
          Equity Crowdfunding is a method adapted slowly in the startup industry
          where a group of investors can fund startup companies or small
          businesses in return for a share in equity. It is similar to any other
          kind of investment in shares for a company, the only difference being
          that it can be done online without much hassle. If the company makes
          profit, the value of its shares goes up, thereby helping the investors
          gain return on their investment. Equity crowdfunding is also commonly
          known as Investment Crowdfunding, Crowd Investing or Crowd Equity.
        </>
      ),
    },
    {
      id: "2",
      title: "WHAT IS EQUITY CROWDFUNDING SOFTWARE?",
      desc: (
        <>
          Equity Crowdfunding Software is basically a script that enables you to
          set up an Equity Crowdfunding platform in the form of a portal or a
          website where entrepreneurs or equity fundraisers can invite
          interested people to invest in their startups through our online
          Crowdfunding portal approach or under the{" "}
          <span className="demo-cont-span">Crowdfunding DIY Approach</span>.
          With the help of this software, you can develop a full-fledged white
          label Equity Crowdfunding website with{" "}
          <span className="demo-cont-span">advanced features </span>
          advanced features, or create a clone of other popular crowdfunding
          sites like Equitynet, Crowdfunder, Crowdcube, Onevest, etc. The script
          helps the project creators with a simple installation process which
          enables them to start a project within minutes even without any
          programming or coding expertise.
        </>
      ),
    },
  ],
  socialMediaProps = {
    title:
      "HOW DOES THE EQUITY CROWDFUNDING SCRIPT HELP YOU?HOW DOES THE EQUITY CROWDFUNDING SCRIPT HELP YOU?",
    desc: (
      <>
        <div className="soc-blw-div-sub">
          <p className="soc-blw-div-sub-main">
            At FundraisingScript.com, we can help you to set up your own first
            ever Equity Crowdfunding platform within a matter of minutes. With
            our script, you can install a readymade Crowdfunding setup that
            offers the feasibility of raising funds, allocating shares and
            setting up the face value of shares for different projects listed by
            your clients.
          </p>
        </div>
        <div className="soc-blw-div-sub">
          <p className="soc-blw-div-sub-main">
            Our Equity Crowdfunding script adheres to the guidelines of JOBS Act
            and SEIS Act of USA and UK respectively in order to keep you away
            from legal loopholes or hurdles. The script features solid security
            measures and modern day demands of a quality website such as
            responsive design, fast loading speed and clean codes, etc.
          </p>
        </div>
      </>
    ),
    title2: "HOW DOES THE EQUITY CROWDFUNDING SCRIPT WORK?",
    desc2: (
      <>
        <div className="soc-blw-div-sub">
          <p className="soc-blw-div-sub-main">
            Once you install our Equity Crowdfunding script on your site, it
            will be transformed into a full-fledged Equity Crowdfunding portal.
            Companies can register on the website and list their equity
            offerings; these will be online after the site admin approves the
            project.
          </p>
        </div>
        <div className="soc-blw-div-sub">
          <p className="soc-blw-div-sub-main">
            Once the project goes live, investors can visit the portal and
            invest in the equity offerings. Our Software is developed in such a
            way that all the investment on equity offerings through the web
            portal will be split into two parts –one part of the investment will
            go to the company that listed the equity offering and the other part
            will be kept by the site admin as fees for providing the platform to
            collect the equity funds.
          </p>
        </div>
        <div className="soc-blw-div-sub">
          <p className="soc-blw-div-sub-main">
            Site admin holds the right to determine the percentage of share from
            the investments beforehand and hence it becomes a win-win scenario
            for both, the project creator as well as the site admin. More
            details can be found on the workflow chart below.
          </p>
        </div>
      </>
    ),
  },
}) => {
  return (
    <>
      <div className="demo-overview">
        <Container>
          <div className="demo-ovr-inner">
            <Grid container direction="row" spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                {contents.map((item) => (
                  <Fragment key={item.id}>
                    <div className="demo-ovr-content-title">
                      <p className="demo-ovr-cont-title-main">{item.title}</p>
                    </div>
                    <div className="demo-ovr-content-sub">
                      <p className="demo-ovr-cont-sub-main">{item.desc}</p>
                    </div>
                  </Fragment>
                ))}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <div className="demo-ovr-right">
                  <img className="demo-ovr-img" src={img} alt="demo" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <SocialMedia
        title={socialMediaProps.title}
        desc={socialMediaProps.desc}
        title2={socialMediaProps.title2}
        desc2={socialMediaProps.desc2}
      />
      <SocialHigh />
      <ServerApi />
    </>
  );
};

export default DemoOverview;
