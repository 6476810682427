import React from "react";
import { NavLink } from "react-router-dom";

const EnergyBanner = () => {
  return (
    <div className="energy-ban-div">
      <div className="energy-ban-inner">
        <div></div>
        <div className="energy-ban-hero">
          <p className="energy-ban-hero-title">Renewable Energy Crowdfunding</p>
          <p className="energy-ban-hero-sub">
            A crowdfunding platform especially developed to fulfill the
            requirements of green energy funding. The platform will help your
            clients to present the green energy project in the most appealing
            way.
          </p>
          <p className="energy-ban-hero-sub2">Go Green! Fund Green!</p>
          <NavLink to="/contact">
            <button className="energy-ban-hero-btn">CONTACT US NOW!!</button>
          </NavLink>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default EnergyBanner;
