import * as React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function BasicTable({ title, itemOne, itemTwo, itemThree }) {
  return (
    <div className="tablegrid">
      <div className="tab-price">
        {title && (
          <div className="price-tbl-ttl">
            <p className="price-tbl-ttl-main price-table-title-index">
              {title}
            </p>
          </div>
        )}
        {itemOne.map((item, index) => (
          <span className="tableItems" key={index}>
            <p className="tbl-index-ttl">{item.title}</p>
            {/* {console.log(typeof itemOne[index].value)} */}
            <p className="tbl-index-val">
              {typeof itemOne[index].value === "boolean" ? (
                itemOne[index].value ? (
                  <CheckCircleIcon className="check-color" />
                ) : (
                  <CancelIcon className="wrong-color" />
                )
              ) : (
                itemOne[index].value
              )}
            </p>
            <p className="tbl-index-val">
              {typeof itemTwo[index].value === "boolean" ? (
                itemTwo[index].value ? (
                  <CheckCircleIcon className="check-color" />
                ) : (
                  <CancelIcon className="wrong-color" />
                )
              ) : (
                itemTwo[index].value
              )}
              {/* {itemTwo[index].value}</p> */}
            </p>
            <p className="tbl-index-val">
              {/* {itemThree[index].value} */}
              {typeof itemThree[index].value === "boolean" ? (
                itemThree[index].value ? (
                  <CheckCircleIcon className="check-color" />
                ) : (
                  <CancelIcon className="wrong-color" />
                )
              ) : (
                itemThree[index].value
              )}
            </p>
          </span>
        ))}
      </div>
    </div>
  );
}
