import React from "react";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { NavLink } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Container } from "@mui/material";
import PriceCard from "pages/PriceCard";
import PriceTable from "pages/PriceTable";

const DemoPrice = () => {
  const activeData = [
    {
      title: "Software & License",
      content: [
        {
          title: "Basic",
          sub: <span className="popular-main-price">$999</span>,
          values: [
            {
              title: (
                <>
                  Fully featured fundraising script
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: true,
            },
            {
              title: (
                <>
                  Custom Design
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: false,
            },
            { title: "Responsive Design", value: true },
            {
              title: (
                <>
                  Domain License
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: 1,
            },
            {
              title: (
                <>
                  Encrypted Files
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: false,
            },
          ],
        },
        {
          title: "Popular",
          sub: <span className="popular-main-price">$1499</span>,
          values: [
            {
              title: "Fully featured fundraising script question icon",
              value: true,
            },
            { title: "Custom Design", value: false },
            { title: "Responsive Design", value: true },
            { title: "Domain License", value: 1 },
            { title: "Encrypted Files", value: false },
          ],
        },
        {
          title: "Custom Develop",
          sub: <span className="custom-main-sub">As per Requirement</span>,
          values: [
            {
              title: "Fully featured fundraising script question icon",
              value: true,
            },
            { title: "Custom Design", value: true },
            { title: "Responsive Design", value: true },
            { title: "Domain License", value: 1 },
            { title: "Encrypted Files", value: true },
          ],
        },
      ],
    },
    {
      title: "Setup",
      content: [
        {
          title: "Basic",
          values: [
            {
              title: "Installation & Configuration",
              value: "1 time free",
            },
            { title: "Latest version", value: true },
            {
              title: "Responsive DesignDetailed Documentation",
              value: true,
            },
            {
              title: (
                <>
                  Call Duration
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: "5 days",
            },
          ],
        },
        {
          title: "Popular",
          values: [
            {
              title: "Installation & Configuration",
              value: "1 time free",
            },
            { title: "Latest version", value: true },
            {
              title: "Responsive DesignDetailed Documentation",
              value: true,
            },
            { title: "Call Duration", value: "5 days" },
          ],
        },
        {
          title: "Custom Develop",
          values: [
            {
              title: "Installation & Configuration",
              value: "5 time free",
            },
            { title: "Latest version", value: true },
            {
              title: "Responsive DesignDetailed Documentation",
              value: true,
            },
            { title: "Call Duration", value: false },
          ],
        },
      ],
    },
    {
      title: "Support",
      content: [
        {
          title: "Basic",
          values: [
            {
              title: "Version Updates",
              value: true,
            },
            { title: "Bug Fixing", value: true },
            {
              title: "Technical issues assistance",
              value: true,
            },
            {
              title: "Free Support Period",
              value: "6 Months",
            },
            {
              title: "Customer Support",
              value: "Email, Support Ticket, Live Chat",
            },
            { title: "Server Requirements", value: "Click Here" },
            { title: "Team Details", value: false },
            { title: "Management Includes", value: false },
          ],
        },
        {
          title: "Popular",
          values: [
            {
              title: "Version Updates",
              value: true,
            },
            { title: "Bug Fixing", value: true },
            {
              title: "Technical issues assistance",
              value: true,
            },
            {
              title: "Free Support Period",
              value: "1 Year",
            },
            {
              title: "Customer Support",
              value: "Email, Support Ticket, Live Chat, Skype, Phone",
            },
            { title: "Server Requirements", value: "Click Here" },
            { title: "Team Details", value: false },
            { title: "Management Includes", value: false },
          ],
        },
        {
          title: "Custom develop",
          values: [
            {
              title: "Version Updates",
              value: true,
            },
            { title: "Bug Fixing", value: true },
            {
              title: "Technical issues assistance",
              value: true,
            },
            {
              title: "Free Support Period",
              value: "1 Year",
            },
            {
              title: "Customer Support",
              value: "Email, Support Ticket, Live Chat, Skype, Phone",
            },
            { title: "Server Requirements", value: "Click Here" },
            { title: "Team Details", value: "1 Developer,1 Project Manager" },
            {
              title: "Management Includes",
              value: "Customer support, Technical issues assistance",
            },
          ],
        },
      ],
    },
    {
      title: "Other Requirements",
      content: [
        {
          title: "Basic",
          values: [
            {
              title: (
                <>
                  Signing Contract
                  <Tooltip title="Click here to read Faqs regarding this.">
                    <NavLink to="/faqs">
                      <HelpIcon className="price-tooltip" />
                    </NavLink>
                  </Tooltip>
                </>
              ),
              value: true,
            },
            { title: "Terms & Conditions", value: "Click Here" },
          ],
        },
        {
          title: "Popular",
          values: [
            {
              title: "Signing Contract",
              value: true,
            },
            { title: "Terms & Conditions", value: "Click Here" },
          ],
        },
        {
          title: "Custom Develop",
          values: [
            {
              title: "Signing Contract",
              value: false,
            },
            { title: "Terms & Conditions", value: "Click Here" },
          ],
        },
      ],
    },
    {
      title: "Addons",
      content: [
        {
          title: "Basic",
          values: [
            {
              title: "iPhone and Android Apps (Native)",
              value: "+ $599",
            },
          ],
        },
        {
          title: "Popular",
          values: [
            {
              title: "iPhone and Android Apps (Native)",
              value: "+ $599",
            },
          ],
        },
        {
          title: "Custom Develop",
          values: [
            {
              title: "Signing Contract",
              value: "-",
            },
          ],
        },
      ],
    },
    {
      title: "",
      content: [
        {
          title: "Basic",
          values: [
            {
              value: (
                <>
                  <NavLink to="/request">
                    <button className="price-tbl-btn">Buy Now</button>
                  </NavLink>
                </>
              ),
            },
          ],
        },
        {
          title: "Popular",
          values: [
            {
              value: (
                <>
                  <NavLink to="/request">
                    <button className="price-tbl-btn">Buy Now</button>
                  </NavLink>
                </>
              ),
            },
          ],
        },
        {
          title: "Custom Develop",
          values: [
            {
              value: (
                <>
                  <NavLink to="/request">
                    <button className="price-tbl-btn">Buy Now</button>
                  </NavLink>
                </>
              ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <div className="demo-price-inner">
        <PriceCard />
        <div className="price-card-button-div">
          <div className="price-card-button-inner">
            <div className="price-card-button-left">
              {/* className={isActive ? 'your_className': null} 
      onClick={toggleClass} */}
              <button className="pc-btn-left-main highlight">Pricing</button>
            </div>
          </div>
        </div>
        <Container>
          <div className="price-tbl-outer">
            <div className="price-tbl-main">
              <div className="price-table-main-inner">
                <div className="price-tbl-head-div">
                  <div className="price-tbl-head-inner">
                    <div className="price-tbl-head-left">
                      <p className="item-head-left-cont">Recommended For</p>
                    </div>
                    <div className="price-tbl-head-right">
                      <div className="price-tbl-head-item-right">
                        <div className="price-item-main-heading">
                          <p className="price-item-main-heading-main">
                            {activeData[0].content[0].title}
                          </p>
                        </div>
                        <div className="price-item-main-sub">
                          <p className="price-item-main-sub-cont">
                            {activeData[0].content[0].sub}
                          </p>
                        </div>
                      </div>
                      <div className="price-tbl-head-item-right">
                        <div className="price-item-main-heading">
                          <p className="price-item-main-heading-main">
                            {activeData[0].content[1].title}
                          </p>
                        </div>
                        <div className="price-item-main-sub">
                          <p className="price-item-main-sub-cont">
                            {activeData[0].content[1].sub}
                          </p>
                        </div>
                      </div>
                      <div className="price-tbl-head-item-right">
                        <div className="price-item-main-heading">
                          <p className="price-item-main-heading-main">
                            {activeData[0].content[2].title}
                          </p>
                        </div>
                        <div className="price-item-main-sub">
                          <p className="price-item-main-sub-cont">
                            {activeData[0].content[2].sub}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <h1>{activeData[0].content[0].title} </h1>
            <h1>{activeData[0].content[1].title} </h1>
            <h1>{activeData[0].content[2].title} </h1> */}
                {activeData &&
                  activeData.map((item, index) => (
                    <PriceTable
                      key={index}
                      title={item.title}
                      itemOne={item.content[0].values}
                      itemTwo={item.content[1].values}
                      itemThree={item.content[2].values}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Container>
        <div className="responsive-tbl">
          <Container>
            <div className="resp-tbl-inner">
              <Accordion className="resp-accordion">
                <AccordionSummary
                  style={{
                    backgroundColor: "#313131",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{activeData[0].content[0].title}</p>
                </AccordionSummary>
                <div className="resp-tbl-acrdn-inner">
                  <p className="resp-title-sub">
                    {activeData[0].content[0].sub}
                  </p>
                  {/* <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails> */}
                  {activeData.map((item, index) => (
                    <div key={index}>
                      <div className="resp-tbl-box-title-div">
                        <p className="resp-tbl-box-title">{item.title}</p>
                      </div>
                      {item.content[0].values.map((item, index) => (
                        <span className="val-fetch" key={index}>
                          <p className="resp-box-title-head">{item.title}</p>
                          <p className="val-fetch-main">
                            {typeof item.value === "boolean"
                              ? iconCheck(item)
                              : item.value}
                          </p>
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion className="resp-accordion">
                <AccordionSummary
                  style={{
                    backgroundColor: "#313131",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{activeData[0].content[1].title}</p>
                </AccordionSummary>
                <div className="resp-tbl-acrdn-inner">
                  <p className="resp-title-sub">
                    {activeData[0].content[1].sub}
                  </p>
                  {/* <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails> */}
                  {activeData.map((item, index) => (
                    <div key={index}>
                      <div className="resp-tbl-box-title-div">
                        <p className="resp-tbl-box-title">{item.title}</p>
                      </div>
                      {item.content[1].values.map((item, index) => (
                        <span className="val-fetch" key={index}>
                          <p className="resp-box-title-head">{item.title}</p>
                          <p className="val-fetch-main">
                            {typeof item.value === "boolean"
                              ? iconCheck(item)
                              : item.value}
                          </p>
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              </Accordion>
              <Accordion className="resp-accordion">
                <AccordionSummary
                  style={{
                    backgroundColor: "#313131",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{activeData[0].content[2].title}</p>
                </AccordionSummary>
                <div className="resp-tbl-acrdn-inner">
                  <p className="resp-title-sub">
                    {activeData[0].content[2].sub}
                  </p>
                  {/* <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails> */}
                  {activeData.map((item, index) => (
                    <div key={index}>
                      <div className="resp-tbl-box-title-div">
                        <p className="resp-tbl-box-title">{item.title}</p>
                      </div>
                      {item.content[2].values.map((item, index) => (
                        <span className="val-fetch" key={index}>
                          <p className="resp-box-title-head">{item.title}</p>
                          <p className="val-fetch-main">
                            {typeof item.value === "boolean"
                              ? iconCheck(item)
                              : item.value}
                          </p>
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              </Accordion>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

const iconCheck = (item) => {
  return item.value ? (
    <CheckCircleIcon className="check-color" />
  ) : (
    <CancelIcon className="wrong-color" />
  );
};

export default DemoPrice;
