import { banner3 } from "assets";
import Banner from "components/core/Banner";
import React from "react";

const PriceBanner = () => {
  return (
    <div className="price-banner-div">
      <div className="price-banner-inner">
        <Banner
          isIntegration={true}
          title={
            <>
              <p className="hero">
                Reward
                <span className="crowd-div-realestate"> Fundraising </span>
                Software or
              </p>
            </>
          }
          title2={
            <>
              <p className="hero">
                <span className="crowd-div-realestate">
                  Reward Crowdfunding
                </span>{" "}
                Script
              </p>
            </>
          }
          subtitle={
            <>
              <p className="real-est-sub-main">
                Create an online portal to attract pledges for your startup
                venture without losing the ownership of the venture either
                through our online crowdfunding platform.
              </p>
            </>
          }
          img={banner3}
        />
      </div>
    </div>
  );
};

export default PriceBanner;
