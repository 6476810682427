import React from "react";
import Footer from "components/footer/Footer";
import { NavHeader } from "components/navheader";

import "scss/index.scss";
import Blog from "components/blog/Blog";
import { BrowserRouter } from "react-router-dom";
import RoutePath from "route/RoutePath";
import ScrollToTop from "pages/ScrollToTop";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop>
          <NavHeader />
          <RoutePath />
          <Blog />
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

export default App;
