import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";

const ProductBanner = () => {
  return (
    <div className="prod-banner">
      <Container>
        <div className="prod-ban-inner">
          <Grid container direction="row" spacing={5} justifyContent="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="prod-left-sec">
                <div className="prod-left-title">
                  <p className="prod-left-title-main">
                    ignio™ – The Autonomous Enterprise Software
                  </p>
                </div>
                <div className="prod-left-sub">
                  <p className="prod-left-sub">
                    Transform your operations across domains using AI and
                    Automation towards an Autonomous Enterprise for improved
                    resilience, assurance, and superior customer experience.
                  </p>
                </div>
                <div className="prod-left-btn-div">
                  <button className="prod-ban-btn">Learn More</button>
                  <button className="prod-ban-btn">Contact Us</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="prod-right-div">
                <img
                  className="prod-ban-img"
                  src="https://digitate.com/wp-content/uploads/2020/10/2.png"
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ProductBanner;
