import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import { CardContent, Tab, Tabs } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import ComputerIcon from "@mui/icons-material/Computer";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DemoFeatures from "pages/DemoFeatures";
import DemoPrice from "./DemoPrice";

const DemoTabs = ({ demoTab, overview, value, setValue }) => {
  return (
    <section>
      <div className="tab-div">
        <Tabs
          value={value}
          onChange={(e, i) => setValue(i)}
          aria-label="icon position tabs example"
          variant="scrollable"
        >
          <Tab icon={<ListIcon />} iconPosition="start" label="Overview" />
          <Tab
            icon={<EmojiObjectsIcon />}
            iconPosition="start"
            label="Features"
          />
          <Tab icon={<LocalOfferIcon />} iconPosition="start" label="Pricing" />
          <Tab icon={<ComputerIcon />} iconPosition="start" label="Demo" />
        </Tabs>
      </div>
      <CardContent sx={{ padding: 0 }}>
        {value === 0 && overview}
        {value === 1 && <DemoFeatures />}
        {value === 2 && <DemoPrice />}
        {value === 3 && demoTab}
      </CardContent>
    </section>
  );
};

export default DemoTabs;
