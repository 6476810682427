import React from "react";

const Work = [
  {
    num: 1,
    desc: "Login with your personalized credentials",
  },
  {
    num: 2,
    desc: "Give a catchy and informative headline to the campaign",
  },
  {
    num: 3,
    desc: "Mention all the required details about the campaign which include the date of commencement of campaign, what is the target amount you are aiming to raise and what would be the time frame you would wish to keep this campaign live",
  },
  {
    num: 4,
    desc: "Once you are done with filling all the details, you can make the campaign live so that all the investors who visit our page regularly can study about you and can invest in the campaign",
  },
  {
    num: 5,
    desc: "Also u can share the link of the campaign to friends and acquaintances to help it gain more and more traction",
  },
  {
    num: 6,
    desc: "Once the campaign date ends and you have met your fundraising target, you can again login and withdraw the fund which has been raised in your fundraiser",
  },
];

const EnergyWorks = () => {
  return (
    <div className="energy-work">
      <div className="energy-work-inner">
        <div className="energy-work-title">
          <p className="energy-work-title-main">
            How Renewable Energy Crowdfunding Works?
          </p>
        </div>
        <div className="energy-work-cards">
          {Work.map((item, index) => (
            <div className="energy-work-card" key={index}>
              <div className="energy-work-num">
                <p className="energy-work-num-main">{item.num}</p>
              </div>
              <div className="eng-work-desc">
                <p className="eng-work-desc-main">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnergyWorks;
