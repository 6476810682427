import { Container, Grid } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const socialCard = [
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Responsive-Design.png",
    title: "Responsive Design",
    sub: "Bootstrap design optimized to be able to used from multiple platforms.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Email-Notification.png",
    title: "Email Notification",
    sub: "User ability to enable/disable email notifications based on different campaigns, user and ownership levels.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Featured-Projects.png",
    title: "Featured Projects",
    sub: "Ability to feature projects along with static banner images in same slider on home page.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Payment-Gateway.png",
    title: "Payment Gateway",
    sub: "Secure and multiple payment gateway integration i.e Paypal adaptive, Stripe and Wallet.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Secure-Platform.png",
    title: "Secure Platform",
    sub: "Secure and multi layer platform to prevent hacks, sql injections and other vulnerabilities.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Invite-Friends.png",
    title: "Invite Friends",
    sub: "Invite friends from social media account i.e Facebook ",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Campaign-Feedback.png",
    title: "Campaign Feedback",
    sub: "Complete campaign feedback module to provide feedback and communicate with campaign owner to launch compaign.",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/Icon-Newsletter-System.png",
    title: "Newsletter System",
    sub: "Easy to use news letter system to get in touch with users.",
  },
];

const SocialHigh = () => {
  return (
    <div className="social-hlt">
      <div className="social-hlt-inner">
        <Container>
          <div className="soc-hlt-title">
            <p className="soc-hlt-title-main">HIGHLIGHTING FEATURES</p>
          </div>
          <div className="soc-hlt-card-div">
            <Grid container direction="row" spacing={5}>
              {socialCard.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <div className="soc-hlt-card">
                    <div className="soc-hlt-card-img">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="soc-hlt-card-title">
                      <p className="soc-hlt-card-title-main">{item.title}</p>
                    </div>
                    <div className="soc-hlt-card-sub">
                      <p className="soc-hlt-card-sub-main">{item.sub}</p>
                    </div>
                  </div>
                </Grid>
              ))}
              {/* <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}></Grid> */}
            </Grid>
          </div>
          <div className="soc-hlt-btn">
            <NavLink to="/equity/features">
              <button className="soc-hlt-btn-main">See All Features</button>
            </NavLink>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SocialHigh;
