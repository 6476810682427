import React from "react";

const EnergyIntro = () => {
  return (
    <>
      <div className="energy-intro-div">
        <div className="energy-intro-inner">
          <div className="eng-int-left">
            <div className="eng-int-title">
              <p className="para-bold-text eng-title-width">
                Introduction To Renewable Energy Crowdfunding
              </p>
            </div>
            <div className="eng-int-sub">
              <p className="para-light-text eng-int-sub">
                RES crowdfunding platform from fundraising script is specially
                designed to support clean energy production through natural
                resources and generate possible revenue from crowdfunding. The
                platform will support all possible methods of energy production
                using natural resources and will be flexible implementing ROI
                and investment process as required.
              </p>
              <p className="para-light-text eng-int-sub">
                Our product is white label crowdfunding and Fundraising platform
                specially designed for renewable energy sources with a one time
                license fee. One can add their brand name, logos, colours,
                content and host it on their server. It’s fully optimized and
                integrated to google apps, MailChimp, and all the necessary
                payment gateways.
              </p>
            </div>
          </div>
          <div className="eng-int-right">
            <img
              className="eng-int-img"
              src="https://d1suf988f4fj5v.cloudfront.net/images/what-renewable-energy.png"
              alt="dem"
            />
          </div>
        </div>
      </div>
      <div className="eng-int-sec2">
        <div className="eng-int-sec2-inner">
          <p className="para-bold-text para-mid eng-int-title-main">
            What Is Renewable Energy Crowdfunding?
          </p>
          <p className="para-light-text para-pad">
            By definition Renewable Energy is often referred to as clean energy,
            comes from natural sources or processes that are constantly
            replenished. Examples are solar energy, wind energy.
          </p>
          <p className="para-light-text para-pad">
            Renewable energy has long been used by humans as source of energy
            using different methods. This time it is with the help of technology
            and for a cause of saving the environment with clean energy
            production.
          </p>
          <p className="para-light-text para-pad">
            We are eager to contribute to the renewable energy sector as a
            fundraising software company. We will be more than happy to assist
            clients round the globe to customize the Renewable Energy
            Crowdfunding Platform as per the legalities and law of their
            countries.
          </p>
        </div>
      </div>
      <div className="eng-int-sec3">
        <div className="eng-int-sec3-inner">
          <div className="eng-int-sec3-title">
            <p className="para-bold-text eng-int-title-main">
              How Does Renewable Energy Crowdfunding Script Helps You?
            </p>
            <p className="para-light-text para-pad">
              Renewable Energy production is the dream replacement of all
              traditional energy resources we are using right now. Of course, we
              are already using renewable energy resources for power generation.
              This software will help businesses to involve people and raise
              public funding for the project.
            </p>
            <p className="para-light-text para-pad">
              Not only the local involvement and interest will make the project
              meaningful but people may take interest based on your steady and
              long term return policies which is possible if we observe closely
              the renewable energy resources and establishments.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnergyIntro;
