import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import {
  donation,
  employee,
  energy,
  equity,
  lending,
  realestate,
  reward,
} from "assets";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, NavLink } from "react-router-dom";

const solutionArr = [
  {
    img: realestate,
    title: "Real Estate CrowdFunding",
    desc: "Know what you won and why you won it",
    link: [
      {
        name: "Demo",
        path: "/realestate/demo",
      },
      {
        name: "Pricing",
        path: "/realestate/pricing",
      },
      {
        name: "Feature",
        path: "/realestate/features",
      },
      {
        name: "Overview",
        path: "/realestate/overview",
      },
    ],
    default: false,
  },
  {
    img: lending,
    title: "P2P Lending",
    desc: "Trust attracts funds",
    link: [
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
    ],
    default: "/lending",
  },
  {
    img: equity,
    title: "Equity CrowdFunding",
    desc: "Just believe in it and do it",
    link: [
      {
        name: "Demo",
        path: "/equity/demo",
      },
      {
        name: "Pricing",
        path: "/equity/pricing",
      },
      {
        name: "Features",
        path: "/equity/features",
      },
      {
        name: "Overview",
        path: "/equity/overview",
      },
    ],
    default: false,
  },
  {
    img: employee,
    title: "Employee CrowdFunding",
    desc: "One for all and all for one",
    link: [
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
    ],
    default: "/employee",
  },
  {
    img: reward,
    title: "Reward CrowdFunding",
    desc: "Get rewarded with bit of your kindness",
    link: [
      {
        name: "Demo",
        path: "/reward/demo",
      },
      {
        name: "Pricing",
        path: "/reward/pricing",
      },
      {
        name: "Features",
        path: "/reward/features",
      },
      {
        name: "Overview",
        path: "/reward/overview",
      },
    ],
    default: false,
  },
  {
    img: energy,
    title: "Renewable Energy CrowdFunding",
    desc: "Your penny towards a better future",
    link: [
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
      {
        name: "",
        path: "",
      },
    ],
    default: "/energy",
  },
  {
    img: donation,
    title: "Donation CrowdFunding",
    desc: " one has become poor by giving",
    link: [
      {
        name: "Demo",
        path: "/donation/demo",
      },
      {
        name: "Pricing",
        path: "/donation/pricing",
      },
      {
        name: "Features",
        path: "/donation/features",
      },
      {
        name: "Overview",
        path: "/donation/overview",
      },
    ],
    default: false,
  },
];

const Solution = () => {
  const navigate = useNavigate();
  return (
    <Container className="main-solution-container d-flex justify-cnt">
      <div className="solution-div-wrapper">
        <Grid container spacing={2}>
          {solutionArr.map((curElm, index) => {
            return (
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                className=""
                key={index}
                // onClick={() => {
                //   index % 2 === 0 && navigate(curElm?.default);
                // }}
              >
                <section
                  className="solution-grid-item d-flex  align-cnt"
                  onClick={() => curElm.default && navigate(curElm.default)}
                >
                  <div className="left-solution-div mr-1">
                    <img src={curElm.img} alt="business-logo" />
                  </div>
                  <div className="right-solution-div d-flex justify-btw align-cnt w-100">
                    <div>
                      {/* <NavLink to={curElm.default && curElm.default}> */}
                      <Typography variant="body1" className="font-size-0_8">
                        <strong>{curElm.title}</strong>
                      </Typography>

                      <Typography
                        variant="body2"
                        className={`${index % 2 === 0 && "solution-desc"}`}
                      >
                        {curElm.desc}
                      </Typography>

                      <ul
                        // className="hover-solution-desc d-none"
                        className={`font-size-0_8  d-none ${
                          index % 2 === 0 && "hover-solution-desc "
                        }`}
                      >
                        <li className="list-style-none solution-li li-border mr-0_5">
                          <NavLink
                            to={curElm?.link?.[3]?.path}
                            className="mr-0_5"
                          >
                            {curElm?.link?.[3]?.name}
                          </NavLink>
                        </li>
                        <li className="list-style-none solution-li li-border mr-0_5">
                          <NavLink
                            to={curElm?.link?.[2]?.path}
                            className="mr-0_5"
                          >
                            {curElm?.link?.[2]?.name}
                          </NavLink>
                        </li>
                        <li className="list-style-none solution-li li-border mr-0_5">
                          <NavLink
                            to={curElm?.link?.[1]?.path}
                            className="mr-0_5"
                          >
                            {curElm?.link?.[1]?.name}
                          </NavLink>
                        </li>
                        <li className="list-style-none solution-li">
                          <NavLink
                            to={curElm?.link?.[0]?.path}
                            className="mr-0_5"
                          >
                            {curElm?.link?.[0]?.name}
                          </NavLink>
                        </li>
                        {/* Overview | feature | Pricing | Demo */}
                      </ul>
                      {/* </NavLink> */}
                    </div>

                    <span className="solution-right-icon-span ">
                      <NavigateNextIcon className=" font-size-0_9 " />
                    </span>
                  </div>
                </section>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default Solution;
