import BannerCard from "components/core/BannerCard";
import PaymentCard from "components/core/PaymentCard";

const integrate = [
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/paypal-img.png",
    title: "PayPal",
    sub: "Because PayPal makes payment easy and safe, it is one of the most accepted and favorite payment gateways in the world. PayPal is placed among the top 10 in the list of firstborn and best-known online payment gateways used to send and receive money online. PayPal users can create an intermediate account between the buyer and seller for secure and convenient money transfer. It also comes up with various supportive features such as barcode scanning, mobile card, and credit card readers. PayPal supports online transactions by accepting 100 different currencies and currently PayPal is operational in 200 countries around the globe.",
    link: "Lean more about PayPal",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/stripe-img.png",
    title: "Stripe",
    sub: "Stripe is actually a cloud-based online payment method and a leading choice for online credit card handling. As compared to other payment gateways Stripe offers a collection of APIs. These APIs are helpful for merchants as they allow them to add new payment options. According to several online merchants Stripe is their preferred option. Stripe applies modern scam avoidance procedures and offers tough security that keeps global sales smooth. Stripe processes and deals in more than 135 different currencies. Unique features of Stripe for which it is preferred are mobile user interface, custom UI toolkit, fraud shield, periodic payments, and 24/7 expert support.",
    link: "Lean more about Stripe ",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/plaid-img.png",
    title: "Plaid",
    sub: "Plaid makes it easy to connect to your bank and safely shares your bank account information with the applications and software you want to use. Plaid is associated with more than 11,000 economic organizations in Europe, the United States, and Canada. When you make an account on Plaid and set your financial information, it encrypts your information immediately. Whenever you use a monetary app, Plaid safely connects and shares your bank information with the app, but not confidential details like passwords. Plaid is highly protected and it is also used by famous online transaction apps like Venmo and Stripe.",
    link: "Lean more about Plaid ",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/mangopay-img.png",
    title: "Mangopay",
    sub: "MangoPay is a service that offers a better online payment method for its users in which merchants can make their e-wallets to collect payment from their buyers and sellers automatically. MangoPay is integrally customizable and specially created for marketplaces, and crowdfunding programs. In MangoPay transactions and funds are electronically handled through e-wallets. It is considered suitable for international markets as it allows the users to send and receive payments in multiple currencies. This online platform uses KYC practices (Know Your Customer) and anti-money laundering security that fit with European guidelines.",
    link: "Lean more about Mangopay ",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/lemonway-img.png",
    title: "LemonWay",
    sub: "LemonWay is an online transaction service supplier that certifies effective and protected ways for managing stakeholders’ transactions. LemonWay is a 100% adaptable and integrated solution for cooperative economy participants and crowdfunding programs. The service also comes with updated options like personalized payment statements, customizable transactions, and accepts mobile payments anywhere. Moreover, LemonWay also validates many renowned third-party transaction platforms and allows access to a range of markets internationally where you can trade with the help of professional consultants. When talking about modernization and upgradations, LemonWay is leading in this respect because it keeps upgrading and improving its service to stay in the competition.",
    link: "Lean more about LemonWay ",
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/integration/goji-img.png",
    title: "Goji",
    sub: "Goji is a financing platform and Advanced Finance administrator. It works in coordination with direct investors and financing managers presenting them the technology for faster payments on their white-label accounts. Their system says Goji will help to manage the payments to many recipients at the same time. With a squad of Customer Service Executives, Goji approximately administers 12,000 accounts at present for several platforms. The company says it has established an API first client money facility to hold depositor's funds. And permitted platforms are allowed to access cleared funds through faster payments system. Goji's program provides the users with innovative technology, safety, and flexibility for their systems.",
    link: "Lean more about Goji ",
  },
];

const PaymentGateway = () => {
  return (
    <>
      <div className="pg-div">
        <div className="pg-inner">
          <BannerCard title="Payment Gateway" sub="" />
        </div>
      </div>
      <div className="pay-card-div">
        <div className="pay-card-inner">
          {integrate.map((item, index) => (
            <PaymentCard
              key={index}
              img={item.img}
              title={item.title}
              sub={item.sub}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentGateway;
