import { Container, Button, Typography, Grid } from "@mui/material";
import { cardimg1, cardimg2, cardimg3, cardimg4, cardimg5 } from "assets";
import React from "react";
import { NavLink } from "react-router-dom";

const cardListArr = [
  {
    title: "Reward Crowdfunding",
    desc: "Now you can set up your own Crowdfunding platform like Kickstarter or Indiegogo. Simply install our Reward-based Crowdfunding script that will help you to create a Reward-based Crowdfunding platform on your website with just a single click.",
    img: cardimg1,
    more: (
      <NavLink to="/reward/demo">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          More details
        </Button>
      </NavLink>
    ),
    demo: (
      <a
        href="https://reward.yardfundraising.searchingyard.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          See demo
        </Button>
      </a>
    ),
  },
  {
    title: "Donation Crowdfunding",
    desc: "Want to develop a similar crowdfunding sites like Gofundme, Fundly or Crowdrise to help Companies and Organizations to generate donations? Our Donation-based Crowdfunding script would be helpful in setting up your own Donation-based Crowdfunding platform within a matter of seconds.",
    img: cardimg2,
    more: (
      <NavLink to="/donation/demo">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          More details
        </Button>
      </NavLink>
    ),
    demo: (
      <a
        href="http://donation-crowdfunding.yardfundraising.searchingyard.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          See demo
        </Button>
      </a>
    ),
  },
  {
    title: "Equity Crowdfunding",
    desc: "Are you planning to set up your own Crowdfunding platform for Equity fundraisers? Here’s an ultimate solution for you. Use our Equity Crowdfunding script and build a highly efficient, responsive and secured Equity Crowdfunding platform.",
    img: cardimg3,
    more: (
      <NavLink to="/equity/demo">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          More details
        </Button>
      </NavLink>
    ),
    demo: (
      <a
        href="https://equity.yardfundraising.searchingyard.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          See demo
        </Button>
      </a>
    ),
  },
  {
    title: "Realestate Crowdfunding",
    desc: "Now easily install a Real Estate Crowdfunding platform on your website using our Crowdfunding script customized for Real Estate market. With this platform, you can offer a great space for Real Estate developers to list their projects in order to invite investments from the people visiting the platform.",
    img: cardimg4,
    more: (
      <NavLink to="realestate/demo">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          More details
        </Button>
      </NavLink>
    ),
    demo: (
      <a
        href="https://realestate.yardfundraising.searchingyard.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          See demo
        </Button>
      </a>
    ),
  },
  {
    title: "Lending Crowdfunding",
    desc: "Our lending Crowdfunding script is the ideal choice to install a Lending-based Crowdfunding platform on your website like Kiva. With the help of our Lending Crowdfunding script, you can easily set up a Crowdfunding platform where you can encourage Companies or Individuals to list their projects to invite lenders to lend funds into the project in return of good interest rates.",
    img: cardimg5,
    more: (
      <NavLink to="/lending">
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          More details
        </Button>
      </NavLink>
    ),
    demo: (
      <a
        href="https://yardfundrasing-admin.web.app/"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outlined"
          className="mini-card-btn btn-outlined"
          size="small"
          disableElevation
        >
          See Admin Demo
        </Button>
      </a>
    ),
  },
];

const Card = () => {
  return (
    <div className="main-card-bg">
      <Container className="main-card-container ">
        <Grid
          container
          rowSpacing={10}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid
            item
            lg={12}
            justifyContent={"center"}
            alignItems={"center"}
            className="text-align-cnt"
          >
            <Typography variant="h4" className="new-text-clr pb-1">
              Our Solution
            </Typography>
            <Typography variant="body1" className="new-text-clr pb-1">
              Check out our custom built, highly secured and responsive
              Crowdfunding Software
            </Typography>
          </Grid>
          {cardListArr.map((curElm, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <div className="main-card-div d-flex flex-column justify-btw align-cnt">
                  <div className="card-img-div text-align-cnt">
                    <img src={curElm.img} alt="card-icon" />
                    <Typography
                      variant="h6"
                      className="card-typo-title new-text-clr"
                    >
                      <strong> {curElm.title}</strong>
                    </Typography>
                  </div>

                  <Typography
                    variant="body2"
                    className="card-typo-desc new-text-clr text-align-cnt"
                  >
                    {curElm.desc}
                  </Typography>
                  {/* <div className="lower-card-div"> */}
                  {/* <div className="d-flex flex-column align-cnt">
                      <div className="mini-card-title"></div>
                    </div>
                    <div className="card-desc-div  pt-1 pb-1">
                    </div> */}
                  <div className="btn-card-div d-flex justify-cnt g-1 pt-1">
                    {curElm.more}
                    {curElm.demo}
                  </div>
                  {/* </div> */}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Card;
