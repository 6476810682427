import React from "react";

const EnergyCard = ({ title, sub, img }) => {
  return (
    <div>
      <div className="eng-hlt-card">
        <div className="eng-hlt-card-content">
          <div className="eng-hlt-card-title">
            <p className="eng-hlt-card-title-main">{title}</p>
          </div>
          <div className="eng-hlt-card-sub">
            <p className="eng-hlt-card-sub-main">{sub}</p>
          </div>
          <div className="eng-hlt-card-icon-div">
            <img className="eng-card-img" src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyCard;
