import React, { useEffect, useState } from "react";
import Banner from "components/core/Banner";
import { banner2, demos2 } from "assets";
import DemoTabs from "components/core/DemoTabs";
import Demopage from "./Demopage";
import DemoOverview from "./DemoOverview";
import { NavLink, useParams } from "react-router-dom";

const Equity = () => {
  const [value, setValue] = useState(0);
  const { type } = useParams();
  useEffect(() => {
    switch (type) {
      case "demo":
        setValue(3);
        break;
      case "pricing":
        setValue(2);
        break;
      case "features":
        setValue(1);
        break;

      default:
        setValue(0);
    }
    return () => {
      setValue(0);
    };
  }, [type]);
  return (
    <>
      <div className="equity-div">
        <div className="equity-inner">
          <Banner
            isIntegration={true}
            title={
              <>
                <p className="hero">
                  Equity
                  <span className="crowd-div-realestate"> Crowdfunding </span>
                  Software
                </p>
              </>
            }
            title2={
              <>
                <p className="hero">
                  or
                  <span className="crowd-div-realestate">
                    {" "}
                    Crowd Investing{" "}
                  </span>{" "}
                  Script
                </p>
              </>
            }
            subtitle={
              <>
                <p className="real-est-sub-main">
                  Leverage our equity crowdfunding software, have it customised
                  for your needs and grow your business.
                </p>
              </>
            }
            img={banner2}
            btn1={
              <>
                <NavLink to="/equity/pricing">
                  <button className="real-est-btn1-main">View Prices</button>
                </NavLink>
              </>
            }
            btn2={
              <>
                <NavLink to="/equity/features">
                  <button className="real-est-btn2-main">Check Features</button>
                </NavLink>
              </>
            }
          />
        </div>
      </div>
      <DemoTabs
        value={value}
        setValue={setValue}
        overview={<DemoOverview />}
        demoTab={
          <Demopage
            info={
              <>
                Equity based crowd funding platform is designed and developed
                specially to meet your requirements and fulfill your dream of a
                successful equity based crowdfunding platform. Start your equity
                crowdfunding platform where business/company can post their
                equity offerings and investors can invest to receive
                equity/share in company.
              </>
            }
            cardInfo={[
              {
                title: "Front End Demo",
                subtitle: (
                  <p className="demo-card-para-main">
                    {/* <span className="demo-card-para-ref"></span>. */}
                  </p>
                ),
                img: demos2,
                btn: (
                  <a
                    href="https://equity.yardfundraising.searchingyard.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
              {
                title: "Admin Demo",
                subtitle: (
                  <div className="demo-card-para2">
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Username: </span>{" "}
                        admin
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="demo-card-admin-text">Password:</span>{" "}
                        Admin@123
                      </p>
                    </div>
                  </div>
                ),
                img: "https://d1suf988f4fj5v.cloudfront.net/images/demo-realestate-a.png",
                btn: (
                  <a
                    href="https://yardfundrasing-admin.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="demo-card-btn-main">Try Demo</button>
                  </a>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
};

export default Equity;
