import { Container } from "@mui/material";
import BannerCard from "components/core/BannerCard";
import FaqsQA from "components/core/FaqsQA";
import { NavLink } from "react-router-dom";

const FaqsBanner = () => {
  const FaqsData = [
    {
      title: "Features",
      content: [
        {
          question: "What are the features included in basic package script?",
          ans: (
            <>
              All packages script include same features as listed at:{" "}
              <NavLink to="/price">
                <span className="faq-link-ans">click here</span>
              </NavLink>
            </>
          ),
        },
        {
          question: "What are the features included in open source script?",
          ans: (
            <>
              All packages script include same features as listed at:{" "}
              <NavLink to="/price">
                <span className="faq-link-ans">click here</span>
              </NavLink>
            </>
          ),
        },
      ],
    },
    {
      title: "Design & Package",
      content: [
        {
          question: "What is responsive web design?",
          ans: (
            <>
              Responsive web design (RWD) is an approach to web design aimed at
              crafting sites to provide an optimal viewing experience—easy
              reading and navigation with a minimum of resizing, panning, and
              scrolling—across a wide range of devices (from desktop computer
              monitors to mobile phones).{" "}
            </>
          ),
        },
        {
          question: "What is custom design and what does it included?",
          ans: "Custom design is website template designed as per custom and unique requirements. Fundraisingscript.com will keep your custom design unique and exclusive for you.",
        },
        {
          question: "How can I provide my custom design requirements?",
          ans: "Provide your custom and unique design requirements in terms of text (email), layout design, and screenshot from another website you like most, wireframes, and mock-up or reference links to website you like most. Many times we are sending few question and answer of all question would be your best design requirement. We are pretty good at this.",
        },
        {
          question:
            "Can I add custom design bundle/combo to open source package?",
          ans: "Yes, of course",
        },
        {
          question:
            "Can I add custom design bundle/combo to hiring crowdfunding developer package?",
          ans: "Yes, of course",
        },
        {
          question: "What it will cost to add custom design bundle/combo?",
          ans: (
            <>
              To know more about cost:{" "}
              <NavLink to="/contact">
                <span className="faq-link-ans">Contact us now</span>
              </NavLink>
            </>
          ),
        },
        {
          question: "What is Basic Package?",
          ans: (
            <>
              Basic Package is pricing plan bundle where you will get script
              with all features included
              <NavLink to="/price">
                <span className="faq-link-ans"> here </span>
              </NavLink>{" "}
              and with same design as demo{" "}
              <NavLink to="/price">
                <span className="faq-link-ans"> here </span>
              </NavLink>{" "}
              Few files(models ,controllers files) will be encrypted in this
              package for license purpose.
            </>
          ),
        },
        {
          question: "What is Open Source Package?",
          ans: "Open Source Package is pricing plan bundle where you will get script with all features included here and with same design as demo here. All files will be open source, no encrypted files at all in this package.",
        },
        {
          question: <>What is "Hire Developer" Package?</>,
          ans: "Open Source Package is pricing plan bundle where you will get script with all features included here and with same design as demo here. All files will be open source, no encrypted files at all in this package.",
        },
        {
          question:
            "What is difference between Basic, Open source and Hire Package Developer packages?",
          ans: (
            <>
              Please check pricing package comparison table
              <NavLink to="/price">
                <span className="faq-link-ans"> here </span>
              </NavLink>
              .
            </>
          ),
        },
      ],
    },
    {
      title: "Domain License, Encrypted & Open Source Files",
      content: [
        {
          question: "What is domain license and how does it works?",
          ans: "Domain license is providing your ability to use script/software on particular domain only using encrypted files. For example your domain name is fundraisingscript.com mean you can host our software on fundraisingscript.com. You are not allowed to host or use for any other domain.",
        },
        {
          question: "What are encrypted files?",
          ans: (
            <>
              Encrypted files are part of source code which is compiled or
              encoded files which can be decoded by particular plugin on
              webserver and not editable directly by human/developer.
            </>
          ),
        },
        {
          question: "Can I edit/update encrypted files?",
          ans: "No, does not mean it will stop you to modify/update/add new feature to software. You have 100% capability for modification of software. We are 100% sure it will not stop you to do anything. Please contact us if you have any problem.",
        },
        {
          question: "What files will be encrypted?",
          ans: "All models ,controllers files included in models,controllers folders of our script will be encrypted.",
        },
        {
          question: "Why do you encrypt files?",
          ans: "To protect our source code and allow you to use script only for your purchased domain license only.",
        },
        {
          question:
            "As few files encrypted can I still customize design and add new features?",
          ans: (
            <>
              Yes, of course you can change design from View folder, Controller
              and CSS files. You can add new features to script. You must have
              knowledge of code igniter framework to do this. Please remember
              you need technical skill to do this. Non – IT person can’t do
              this.
            </>
          ),
        },
        {
          question: "What are open source files?",
          ans: "Open source files are those code files with readable format, which means you can view source code within those files and edit code whatever way you want. You will get everything in source code. No encryption. It is for someone who wants open source for their project. You have unlimited access on domain extension.",
        },
        {
          question: "What kind of source code files included in all packages?",
          ans: (
            <>
              <ol className="faq-list-ans">
                <li>
                  {" "}
                  Basic package will come up with open source view and encrypted
                  controller and models files.
                </li>
                <li>
                  {" "}
                  Open source package will come up with 100% open source files,
                  which means there will be NO ENCRYPTED FILES (NOT AT ALL) in
                  this package.
                </li>
                <li>
                  Hiring package will come up with 100% open source files, You
                  required to hire our developer for minimum 4 month at
                  $3000/per month or more. There is another price for other
                  people $5500/ per months and min 2 month or more month
                  contract required.
                </li>
              </ol>
            </>
          ),
        },
      ],
    },
    {
      title: "Contract, Agreement, Affidavit",
      content: [
        {
          question:
            "What is contract, what does it include and why signing it is required?",
          ans: (
            <>
              <ul className="faq-ans-ul">
                <li>
                  Contract is legal document signed between two parties(You and
                  Fundraisingscript.com in this case) which includes terms of
                  services, terms of payments, bounds and other confirmations-
                  agreements from both parties before purchase of product.
                </li>
                <li>
                  Signing contract is required to make sure that you have read
                  and understood what we are offering in product package you are
                  buying and you are aware of it as well as you are agreed to it
                  if you are signing it.
                </li>
              </ul>
            </>
          ),
        },
        {
          question: "Can I take a look at draft/demo contract?",
          ans: (
            <>
              Sure
              <NavLink to="/contact">
                <span className="faq-link-ans"> Contact us now</span>
              </NavLink>
            </>
          ),
        },
        {
          question:
            "What is agreement, what does it include and why signing it is required?",
          ans: "Agreement is document signed for mutual understanding which will include terms of server, terms of use and terms of payments. Signing agreement is required to make sure that you have read and understood what we are offering in product package you are buying and you are aware of it as well as you are agreed to it if you are signing it.",
        },
        {
          question: "Can I take a look at draft/demo contract?",
          ans: (
            <>
              Sure
              <NavLink to="/contact">
                <span className="faq-link-ans"> Contact us now</span>
              </NavLink>
            </>
          ),
        },
        {
          question:
            "What is affidavit, what does it include and why signing it is required?",
          ans: "Affidavit is legal notary document you have done through domestic lawyer and signed to make sure you are going to strictly follow what is written in affidavit and ready to pursue any legal action if breach to that affidavit signed by you is found.",
        },
        {
          question:
            "Can I take a look at draft/demo affidavit content and how does it look like?",
          ans: (
            <>
              Sure
              <NavLink to="/contact">
                <span className="faq-link-ans"> Contact us now</span>
              </NavLink>
            </>
          ),
        },
      ],
    },
    {
      title: "Miscellaneous",
      content: [
        {
          question: "Is this script come with one domain license?",
          ans: "Yes this script comes with single domain license. If you want to install it on other domains you have to pay fee for each domain license.",
        },
        {
          question:
            "Do you offer script that satisfies all my customization needs?",
          ans: "Yes we offer script and website customization service and support which best fits our client requirements with each license.",
        },
        {
          question:
            "Can I modify the code of & script of website according to my requirements?",
          ans: "Yes you can change everything you want, anything you want in given script to meet your requirements. Script is Encrypted on Model-Controller. You can modify all the code in Application- and View folder for Font side and Admin side Application-admin-Controller and View folder. You can add all your feature by making modules already one Modules folder in it which is empty don't delete it.",
        },
        {
          question: "What support does the company provide and how long?",
          ans: "15 Days for Basic Package and 1 year for Open Source Package expert online support is available with each license. Fundraisingscript.com provides the online live support for handling their scripts, bugs & errors fix support.",
        },
        {
          question: "What are the payment services available?",
          ans: "We are accepting payments via Bank Transfer & Bitcoin only.",
        },
        {
          question:
            "Which programing language and frame work used for fundraising script?",
          ans: (
            <>
              Whole <strong>fundraising script</strong> is built using valid
              php, xhtml, jquery-ajax coding and php framework called
              <strong>"CodeIgniter"</strong>, and its{" "}
              <strong>MVC structure</strong>.
            </>
          ),
        },
        {
          question:
            "Can I bundle/combo basic package with hiring crowdfunding developer?",
          ans: "Yes, you can buy basic package and get dedicated developer to work for a month on your custom features and requirements.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="faqs-banner">
        <div className="faqs-banner-inner">
          <BannerCard
            title="Frequently Asked Questions"
            sub="We are here to give wings to your fundraising ideas. We'll make fundraising website for you."
          />
        </div>
      </div>
      <Container>
        <div className="faq-qa-header">
          <p>
            Read frequently asked questions about our crowd funding/ fundraising
            script.
          </p>
        </div>
      </Container>
      {FaqsData.map((item, index) => {
        return <FaqsQA key={index} data={item} />;
      })}
    </>
  );
};

export default FaqsBanner;
