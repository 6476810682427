import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAMteIDps_7ieOEMJNTBM83kCYV_7ACkNM",
  authDomain: "portfolio-sy-com.firebaseapp.com",
  databaseURL: "https://portfolio-sy-com-default-rtdb.firebaseio.com",
  projectId: "portfolio-sy-com",
  storageBucket: "portfolio-sy-com.appspot.com",
  messagingSenderId: "1083344097953",
  appId: "1:1083344097953:web:da99e98c85693b1dd556e0",
  measurementId: "G-K4R305KVST",
};

const app = firebase.initializeApp(firebaseConfig);
const database = app.database();
export { database };
export default app;
