import LendCard from "components/core/LendCard";
import React from "react";

const LendFeature = [
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-01.png",
    title: "Loan Rates & EMI Calculator",
    sub: "Borrower’s ability to check loan rates before applying for loan based on his/her credit score.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-02.png",
    title: "Loan Origination",
    sub: "Complete loan origination process including loan request form, auto credit score check, loan review, borrower background check, feedback and communication and loan approval.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-03.png",
    title: "Credit Scores",
    sub: "Investor’s ability to get notified when new loan request is launched on lending platform.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-04.png",
    title: "Email Notification",
    sub: "Easily configurable interest rate of loans based on borrower’s credit score in your P2P Lending Software Website.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-05.png",
    title: "Loan Terms",
    sub: "Configure loan terms based on various parameters like credit score of borrower, purpose of loan, yearly income of borrower and so on.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-06.png",
    title: "EMI Configuration",
    sub: "Configure easy EMI terms for borrower for the borrowed loans for easy repayment with either interest or with interest plus principal amount.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-07.png",
    title: "Loan Feedback",
    sub: "Complete loan feedback module to provide feedback and communicate with borrower before approving or rejecting loan application.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-08.png",
    title: "3rd Party APIs",
    sub: "3rd Party API integration (on request, other than default APIs) like credit score, payment gateway, income verification, background check, digital signature, document management and so on.",
    isPremium: true,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-09.png",
    title: "Loan Management",
    sub: "Complete loan management module for admin to manage all loan applications as well as loan in progress and completed loans.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-10.png",
    title: "Investment Management",
    sub: "Easy and complete investment/lending management module to receive confirm and manage investments from investors.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-11.png",
    title: "Auto-Investment/Lend",
    sub: "Investor’s ability to setup and configure auto investment feature based on different parameters in large number of loan requests to diversify investment to lower risk of investment.",
    isPremium: true,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-12.png",
    title: "Pre-Installed APIs",
    sub: "One credit scoring and one payment gateway APIs as pre-installed APIs in our P2P lending software.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-13.png",
    title: "Risk Management",
    sub: "Risk Management with sophisticated credit scoring and automatic calculation of provision pool.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-14.png",
    title: "Dynamic Service Fees",
    sub: "Easily edit and configure percentage fees admin wants to earn from each investment on each loan and repayment of loan.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-15.png",
    title: "Funding Type",
    sub: "Each borrower get funded based on funding type (either fixed or flexible) selected by him/her while applying for loan.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-16.png",
    title: "Responsive Design",
    sub: "Bootstrap design optimized for almost all devices screens and platforms to allow your users to browse your website from anywhere.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-17.png",
    title: "Social Media",
    sub: "Social media integration with different apis like social sharing, social login, social invite.",
    isPremium: false,
  },
  {
    img: "https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-18.png",
    title: "Google Analytics",
    sub: "Integration of Google analytic to track traffic and nature of users.",
    isPremium: false,
  },
];

const LendingFeature = () => {
  return (
    <div className="Lending-feature-div">
      <div className="lend-feature-inner">
        <div className="lend-feature-title">
          <p className="lend-feature-title-main">FEATURES AT GLANCE</p>
        </div>
        <div className="lend-feature-sub">
          <p className="lend-feature-sub-main">
            Here are the highlighting features of our P2P lending software at
            glance. Our feature rich P2P lending Software includes all features
            to run your successful P2P lending website. Contact us to know
            inquire about more features and flow.
          </p>
        </div>
        <div className="lend-feature-cards">
          <div className="lend-cards-inner">
            {LendFeature.map((item, index) => (
              <LendCard
                key={index}
                img={item.img}
                title={item.title}
                sub={item.sub}
                isPremium={item.isPremium}
              />
            ))}
            {/* <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-01.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">
                  Loan Rates & EMI Calculator
                </p>
                <button className="lend-card-btn">Premium</button>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Borrower’s ability to check loan rates before applying for
                  loan based on his/her credit score.
                </p>
              </div>
            </div>
            <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-02.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">Loan Origination</p>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Complete loan origination process including loan request form,
                  auto credit score check, loan review, borrower background
                  check, feedback and communication and loan approval.
                </p>
              </div>
            </div>
            <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-03.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">Email Notification</p>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Investor’s ability to get notified when new loan request is
                  launched on lending platform.
                </p>
              </div>
            </div>
            <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-04.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">
                  Loan Rates & EMI Calculator
                </p>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Investor’s ability to get notified when new loan request is
                  launched on lending platform.
                </p>
              </div>
            </div>
            <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-05.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">
                  Loan Rates & EMI Calculator
                </p>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Investor’s ability to get notified when new loan request is
                  launched on lending platform.
                </p>
              </div>
            </div>
            <div className="lend-feature-card">
              <div className="lend-feature-card-icon">
                <img
                  className="lend-card-img"
                  src="https://d1suf988f4fj5v.cloudfront.net/images/features/pre-order-featyre-06.png"
                  alt=""
                />
              </div>
              <div className="lend-card-title">
                <p className="lend-card-title-main">
                  Loan Rates & EMI Calculator
                </p>
              </div>
              <div className="lend-card-sub">
                <p className="lend-card-sub-main">
                  Investor’s ability to get notified when new loan request is
                  launched on lending platform.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LendingFeature;
