import { Grid } from "@mui/material";
import EnergyCard from "components/core/EnergyCard";
import React from "react";

const CardArray = [
  {
    title: "Responsive Design",
    sub: "Responsive design that supports all screen sizes of both desktop and mobile devices.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Responsive-Design.png",
  },
  {
    title: "Email Notification",
    sub: "User ability to enable/disable email notifications based on different campaigns, user and ownership levels.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Email-Notification.png",
  },
  {
    title: "Payment Gateway",
    sub: "Secure and multiple payment gateway integration i.e Paypal adaptive, Stripe and Wallet.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Payment-Gateway.png",
  },
  {
    title: "Secure Platform",
    sub: "Secure and multi layer platform to prevent hacks, sql injections and other vulnerabilities.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Secure-Platform.png",
  },
  {
    title: "Fees Management",
    sub: "The backend admin can monitor and edit the platform fees that they wish to charge the campaign creators. Also the fees deduction is recorded and credited to the admin account later.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Fees-Management.png",
  },
  {
    title: "Fund Distribution",
    sub: "Well managed and transparent repayment module that follows the repayment rules defined for individual campaigns. The module will auto calculate return amount as per pre-defined rules for each campaign.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Fund-Distribution.png",
  },
  {
    title: "Transaction Traceability",
    sub: "A separate transactions section is managed in the backend that records each and every incoming and outgoing transactions performed over the platform which admin can monitor.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Transaction-Traceability.png",
  },
  {
    title: "ROI Calculator",
    sub: "Get Return on Investment calculated on your investment amount for every project before investing in it. For solar energy projects this module is capable of calculating per cell ROI on investment that is also an inbuilt feature.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Return-Calculator.png",
  },
  {
    title: "Attractive Investor Dashboard",
    sub: "Our detailed investor dashboard with easy navigation and user friendly features will make your investor experience smooth.</",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Investor-Dashboard.png",
  },
  {
    title: "Fraud Detection",
    sub: "The platform is fully secured and even the fraud detection system tracks and prevents any attempt of fraud.",
    img: "https://d1suf988f4fj5v.cloudfront.net/images/rec-features/Icon-Fraud-Detection.png",
  },
];

const EnergyHighlight = () => {
  return (
    <div className="energy-highlight-div">
      <div className="eng-hlt-inner">
        <div className="eng-hlt-title">
          <p className="eng-hlt-title-main">Highlighting Features</p>
        </div>
        <div className="eng-hlt-cards">
          <div className="eng-hlt-cards-inner">
            <Grid container spacing={3}>
              {CardArray.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <EnergyCard
                    title={item.title}
                    sub={item.sub}
                    img={item.img}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyHighlight;
