import { Container } from "@mui/material";
import React from "react";

export default function Banner({
  title,
  title2,
  subtitle,
  img,
  btn1,
  btn2,
  isIntegration,
}) {
  return (
    <>
      <Container className="equity-cont">
        <div className="real-estate-hero">
          <div className="real-estate-hero-left">
            <div className="real-estate-hero-title">{title}</div>
            <div className="real-estate-hero-title2">{title2}</div>
            <div className="real-est-sub">{subtitle}</div>
            <div className="real-est-buttons">
              <div className="real-est-btn1">{btn1}</div>
              <div className="real-est-bt2">{btn2}</div>
            </div>
          </div>
          <div className="real-estate-hero-right">
            <img src={img} className="real-estate-lapi-img" alt="lapi" />
            <div className="real-est-hero-last">
              {isIntegration && (
                <div className="integrate-div">
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">15+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        Payment Gateway Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">10+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        Cryptocurrency Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">10+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        {" "}
                        KYC & AML Integrations
                      </p>
                    </div>
                  </div>
                  <div className="integrate-1">
                    <div className="integrate-num">
                      <p className="integrate-num-main">5+</p>
                    </div>
                    <div className="integrate-para">
                      <p className="integrate-para-main">
                        {" "}
                        E-Signature Integrations
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
