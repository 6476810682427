import React from "react";
import ReqBanner from "./ReqBanner";
import ReqForm from "./ReqForm";

const Request = () => {
  return (
    <div className="request-div">
      <div className="request-inner">
        <ReqBanner />
        <ReqForm />
      </div>
    </div>
  );
};

export default Request;
