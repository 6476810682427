import React from "react";
import BannerCard from "components/core/BannerCard";

const ContactBanner = () => {
  return (
    <div className="contact-banner">
      <div className="contact-banner-inner">
        <BannerCard
          title="Contact Us"
          sub="Please feel free to contact us regarding any questions. Call 081149 57912 or use the following contact form to drop us an email or use online chat, skype and email details given right side of this page."
        />
      </div>
      {/* <div className="contact-location">
        <div className="contact-location-card">
          {address.map((item, index) => (
            <div className="contact-locate-card" key={index}>
              <div className="contact-loc-content">
                <div className="contact-locate-img">
                  <img
                    className="contact-locate-img-main"
                    src={item.img}
                    alt="icon"
                  />
                </div>
                <div className="contact-locate-title">
                  <p className="contact-locate-title-main">{item.title}</p>
                </div>
                <div className="contact-locate-sub">
                  <p className="contact-locate-sub-main">{item.sub}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ContactBanner;
