import { Container } from "@mui/material";
import Card from "components/core/Card";
import React from "react";

const Demopage = ({ cardInfo, info, isAppStore, isGooglePlay }) => {
  return (
    <div className="demo-page">
      <Container className="equity-cont">
        <div className="demo-page-inner">
          <div className="demo-header">
            <div className="demo-header-inner">
              <p className="demo-page-para">{info}</p>
            </div>
            <div className="demo-icons">
              <div className="demo-icons-inner">
                <div className="demo-icon">
                  {isAppStore && (
                    <img
                      className="demo-icons-img"
                      src="https://d1suf988f4fj5v.cloudfront.net/images/App-Store-Icon.png"
                      alt=""
                    />
                  )}
                </div>
                <div className="demo-icon">
                  {isGooglePlay && (
                    <img
                      className="demo-icons-img"
                      src="https://d1suf988f4fj5v.cloudfront.net/images/googl-play-store.png"
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="demo-card-div">
            <div className="demo-card-div-inner">
              {cardInfo.map((item, index) => (
                <Card
                  title={item.title}
                  key={index}
                  subtitle={item.subtitle}
                  img={item.img}
                  btn={item.btn}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Demopage;
