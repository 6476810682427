import React from "react";
import { NavLink } from "react-router-dom";

const EnergyContact = () => {
  return (
    <div className="energy-contact-div">
      <div className="energy-contact-inner">
        <div></div>
        <div className="energy-contact-main">
          <div className="energy-contact-title">
            <p className="energy-contact-title-main">Start like Famous</p>
          </div>
          <div className="energy-contact-sub">
            <p className="energy-contact-sub-main">
              Were you facing challenges attracting investors for your green
              energy project ? Or Are you willing to launch a crowdfunding
              platform for your Renewable Energy projects? We’re now presenting
              our Renewable Energy Crowdfunding platform. White labelled and
              fully customizable! Schedule a demo with us to know more in
              detail.
            </p>
          </div>
          <div className="energy-contact-btn">
            <NavLink to="/contact">
              <button className="energy-contact-btn-main">
                CONTACT US NOW!!
              </button>
            </NavLink>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default EnergyContact;
