import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { blog1, blog2, blog3, blog4, blog5, blog6, blog7, blog8 } from "assets";

const blogArr = [
  {
    img: blog1,
    href: "https://blog.searchingyard.com/advantages-of-crowdfunding/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/advantages-of-crowdfunding/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          Advantages of Crowdfunding
        </div>
      </>
    ),
    desc: "Crowdfunding is nothing but a process of raising funds for a specific project or business. In this process, funds are collected from a large number of investors. It is often done online with social media.",
  },
  {
    img: blog2,
    href: "https://blog.searchingyard.com/how-can-crowdfunding-software-help-schools-universities/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/how-can-crowdfunding-software-help-schools-universities/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          How can Crowdfunding software help schools & Universities?
        </div>
      </>
    ),
    desc: "In this digital era Crowdfunding is no more a new thing. It has been in use for more than a decade and now it’s a very good platform to raise funds for any particular project or business.",
  },
  {
    img: blog3,
    href: "https://blog.searchingyard.com/how-does-agriculture-crowdfunding-help-agriculture/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/how-does-agriculture-crowdfunding-help-agriculture/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          How does agriculture Crowdfunding help agriculture?
        </div>
      </>
    ),
    desc: "Agriculture Crowdfunding is the process of raising funds for any agriculture project from several individuals. Nowadays it is performed through internet-mediated registries.",
  },
  {
    img: blog4,
    href: "https://blog.searchingyard.com/yard-fund-raising-the-best-crowdfunding-platform-development-company/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/yard-fund-raising-the-best-crowdfunding-platform-development-company/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          Yard Fund Raising: The best Crowdfunding platform development company
        </div>
      </>
    ),
    desc: "We provide fully customized Crowdfunding software as per your need. The software developed by us will be fully responsive, modern and compatible with all devices.",
  },
  {
    img: blog5,
    href: "https://blog.searchingyard.com/how-is-white-label-crowdfunding-software-better/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/how-is-white-label-crowdfunding-software-better/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          How is white label Crowdfunding software better?
        </div>
      </>
    ),
    desc: "White-label Crowdfunding software is better and very flexible and customizable; you can customize and redesign it according to your requirement.",
  },
  {
    img: blog6,
    href: "https://blog.searchingyard.com/how-social-media-helps-a-crowdfunding-campaign-to-succeed/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/how-social-media-helps-a-crowdfunding-campaign-to-succeed/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          How social media helps a Crowdfunding campaign to succeed?
        </div>
      </>
    ),
    desc: "An effective social media strategy will increase the awareness of the campaign and will drive more traffics to your site. It could be a best way to reach more people, so the first step is to create a profile on every social media platform while launching your Crowdfunding platform.",
  },
  {
    img: blog7,
    href: "https://blog.searchingyard.com/how-to-start-a-crowdfunding-platform-website-and-what-features-should-it-have/",
    title: (
      <>
        <a
          className="blog-link-title"
          href="https://blog.searchingyard.com/how-to-start-a-crowdfunding-platform-website-and-what-features-should-it-have/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          How to start a Crowdfunding platform website and what features should
          it have?
        </a>
      </>
    ),
    desc: "Before starting about thinking Crowdfunding platform website, your objective should be clear that what kind of Crowdfunding platform do you want for your business?",
  },
  {
    img: blog8,
    href: "https://blog.searchingyard.com/the-most-effective-method-to-build-a-successful-crowdfunding-platform/",
    title: (
      <>
        <div
          className="blog-link-title"
          href="https://blog.searchingyard.com/the-most-effective-method-to-build-a-successful-crowdfunding-platform/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          The most effective method to build a Successful Crowdfunding platform
        </div>
      </>
    ),
    desc: "Before thinking about building a Crowdfunding platform we should know how many types of Crowdfunding are there. Let’s discuss it below:",
  },
];

const Blog = () => {
  return (
    <div className="blog-outer">
      <Container className="main-blog-container d-flex g-1">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h4" className="new-text-clr">
              OUR BLOGS
            </Typography>
          </Grid>
          {blogArr.map((curElm, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <a href={curElm.href} target="_blank;">
                  <div
                    className="main-blog-div-wrapper"
                    style={{
                      justifyContent: "center !important",
                      alignItems: "center !important",
                      margin: "auto !important",
                    }}
                  >
                    <div className="blog-img-div">
                      <img src={curElm.img} alt="logo" />
                    </div>
                    <div className="blog-title-div text-align-cnt ">
                      <Typography
                        variant="body1"
                        className="pb-1 blog-ttl-font"
                      >
                        <strong>{curElm.title}</strong>
                      </Typography>

                      <Typography variant="body2" className="clr-light">
                        {curElm.desc.length > 70
                          ? `${curElm.desc.slice(0, 70)} ...`
                          : curElm.desc}
                      </Typography>
                    </div>
                  </div>
                </a>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Blog;
