import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  hero1,
  hero2,
  hero3,
  hero4,
  // slider_img_1,
  // slider_img_2,
  // slider_img_3,
  // slider_img_4,
} from "assets";
import { NavLink } from "react-router-dom";

const sliderArr = [
  {
    id: 1,
    img: hero1,
    title: "White Label",
    span: "Crowdfunding Software",
    desc: "We understand your needs, hence we don't just offer ready to use crowdfunding software but provide custom crowdfunding software development service, so you get your customized crowdfunding solution to skyrocket your revenue.",
    btn1: (
      <NavLink to="/price">
        <Button
          variant="contained"
          disableElevation
          className="btn-contained mr-1 "
        >
          Buy Now
        </Button>
      </NavLink>
    ),
    btn2: (
      <NavLink to="/contact">
        <Button variant="outlined" disableElevation className="btn-outlined">
          Contact Us
        </Button>
      </NavLink>
    ),
  },
  {
    id: 2,
    img: hero2,
    title: "All-in-one Crowdfunding",
    span: "Software Development Solution",
    desc: "We understand your needs, hence we don't just offer ready to use crowdfunding software but provide custom crowdfunding software development service, so you get your customized crowdfunding solution to skyrocket your revenue.",
    btn1: (
      <NavLink to="/equity/demo">
        <Button
          variant="contained"
          disableElevation
          className="btn-contained mr-1 "
        >
          Demo
        </Button>
      </NavLink>
    ),
    btn2: (
      <NavLink to="/price">
        <Button variant="outlined" disableElevation className="btn-outlined">
          Buy It Now
        </Button>
      </NavLink>
    ),
  },
  {
    id: 3,
    img: hero3,
    title: "Custom Crowdfunding",
    span: "Script Development",
    desc: "We understand your needs, hence we don't just offer ready to use crowdfunding software but provide custom crowdfunding software development service, so you get your customized crowdfunding solution to skyrocket your revenue.",
    btn1: (
      <NavLink to="/contact">
        <Button
          variant="contained"
          disableElevation
          className="btn-contained mr-1 "
        >
          Contact Us Today
        </Button>
      </NavLink>
    ),
    btn2: (
      <NavLink to="/donation/demo">
        <Button variant="outlined" disableElevation className="btn-outlined">
          Demo
        </Button>
      </NavLink>
    ),
  },
  {
    id: 4,
    img: hero4,
    title: "Get an ideal plan for your requirements with",
    span: "one-time payment",
    desc: "We understand your needs, hence we don't just offer ready to use crowdfunding software but provide custom crowdfunding software development service, so you get your customized crowdfunding solution to skyrocket your revenue.",
    btn1: (
      <NavLink to="/price">
        <Button
          variant="contained"
          disableElevation
          className="btn-contained mr-1 "
        >
          Buy It Now
        </Button>
      </NavLink>
    ),
    btn2: (
      <NavLink to="/contact">
        <Button variant="outlined" disableElevation className="btn-outlined">
          Contact Us
        </Button>
      </NavLink>
    ),
  },
];

const MainSlider = () => {
  //
  //
  //
  const [state, setState] = useState(0);

  const divRef = useRef(null);

  useEffect(() => {
    let timeOutFn = setTimeout(() => {
      divRef.current?.scrollBy(divRef.current?.clientWidth, 0);
      if (state === sliderArr.length - 1) {
        divRef.current?.scrollTo(0, 0);
      }
    }, 2000);

    return () => {
      clearTimeout(timeOutFn);
    };
  }, [state]);

  //
  //
  //
  // console.log(state);
  return (
    <>
      <div className="btn-img-div-wrapper">
        <div
          className="slider_style"
          ref={divRef}
          onScroll={() => {
            setState(
              Math.floor(
                divRef.current?.scrollLeft / divRef.current?.clientWidth
              )
            );
          }}
        >
          {sliderArr.map((curElm) => {
            return (
              <div
                className="carousel-item individual_gallery_div"
                key={curElm.id}
              >
                {/* <div className="carousel-caption new-slider"> */}
                <div
                  className={
                    curElm.id % 2 === 0
                      ? "carousel-caption right-5 new-slider"
                      : "carousel-caption left-15 new-slider"
                  }
                >
                  <Typography variant="h4">
                    <strong>{curElm.title}</strong> <br />
                    <span className="clr-light"> {curElm.span}</span>
                  </Typography>
                  <Typography variant="body1" className="pt-1 pb-1">
                    {curElm.desc}
                  </Typography>

                  <div className="first-content-btn-content">
                    {curElm.btn1}
                    {curElm.btn2}
                  </div>
                </div>
                <div className="slider-bg">
                  <img src={curElm.img} alt="slider1" />
                </div>
              </div>
            );
          })}
        </div>

        <div className="main-btn-style">
          <div
            className="btn-div"
            onClick={() => {
              divRef.current?.scrollBy(-divRef.current?.clientWidth, 0);
            }}
          >
            <ArrowBackIosNewIcon className="individual-btn-style" />
          </div>

          <div
            className="btn-div"
            onClick={() => {
              divRef.current?.scrollBy(divRef.current?.clientWidth, 0);
            }}
          >
            <ArrowForwardIosIcon className="individual-btn-style" />
          </div>
        </div>

        <ol className="slider-indicator-ol">
          {sliderArr.map((curElm) => {
            return (
              <li
                key={curElm.id}
                className={
                  curElm.id === state + 1
                    ? "slider-indicator-li indicator-active "
                    : "slider-indicator-li"
                }
              ></li>
            );
          })}
        </ol>
      </div>
    </>
  );
};

export default MainSlider;
