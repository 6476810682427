import React from "react";

export default function Card({ title, subtitle, img, btn }) {
  return (
    <>
      <div className="demo-card">
        <div className="demo-card-inner">
          <div className="demo-card-header">
            <p className="demo-card-heading">{title}</p>
          </div>
          <div className="demo-card-image">
            <img className="demo-card-img-main" src={img} alt="" />
          </div>
          <div className="demo-card-para">{subtitle}</div>
          <div className="demo-card-btn">{btn}</div>
        </div>
      </div>
    </>
  );
}
