import { Container, Typography } from "@mui/material";
import React from "react";

const SecondContent = () => {
  return (
    <div className="second-content-bg">
      <Container className="main-second-content-container">
        <div className="upper-text">
          <Typography
            variant="body2"
            className="line-height new-text-clr pb-1 font-size-0_9"
          >
            Yardfundraising.com – a popular choice for crowdfunding platform
            development solutions, has come up with a new way and version of
            crowdfunding software that helps companies, individuals, and
            entrepreneurs set up their own Crowdfunding platform online.
          </Typography>
          <Typography
            variant="body2"
            className="line-height new-text-clr font-size-0_9"
          >
            Yardfundraising.com aims to help entrepreneurs start a full-fledged,
            state-of-the-art, user-friendly crowdfunding website according to
            their custom needs and niche. Whether looking to start reward based
            similar crowdfunding sites like Kickstarter clone / Kickstarter
            Alternative or Indiegogo Alternative. The white label crowdfunding
            software solution can cater to all your needs with our pre-made
            solutions in addition we can do customized features and integrate
            any third party based on your requirements. Moreover, our Equity and
            Realestate crowdfunding white label solutions have the KYC process
            integrated.
          </Typography>
        </div>
        <div className="lower-text">
          <Typography
            variant="h4"
            className="second-content-title new-text-clr"
            sx={{
              fontSize: {
                lg: 32,
                md: 30,
                sm: 28,
                sx: 25,
              },
            }}
          >
            <strong> Open source VS. White labeled solution </strong>
          </Typography>

          <Typography
            variant="body2"
            className="line-height new-text-clr pb-1 font-size-0_9"
          >
            White-labelled and open source both are popular choices for their
            own benefits. Open Source gives you the flexibility of using the
            entire software code and you can modify it as well. But the upper
            hand for white-labelled is, as the term suggests there’s no branding
            imposed on the software. This means you get to access the entire
            code plus you can add your branding (name, logo etc) and you can be
            ready with your brand new product in the market without investing
            effort or time in its development. So, if you are a business person
            and want to just get started in no time then white labelled is the
            right choice for you. Another flexibility with white labelled is
            that you are free to hire our developer to develop customized
            features which may not be the existing ones but you have ownership
            of your code to make such customizations.
          </Typography>
          <Typography
            variant="body2"
            className="line-height new-text-clr font-size-0_9"
          >
            Also, the open-source software and even some white-labelled
            platforms offer license based service that is of course time-bound.
            Whereas we are selling the white-labelled platforms at one time cost
            which means you pay only once when you purchase and you will own the
            platform for a lifetime.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default SecondContent;
