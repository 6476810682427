import { Container } from "@mui/material";
import React from "react";

const BannerCard = ({ title, sub }) => {
  return (
    <div className="contact-banner-blur">
      <Container className="banner-card-cont">
        <div className="contact-banner-hero">
          <div></div>
          <div className="contact-banner-content">
            <div className="contact-banner-title">
              <p className="contact-banner-title-main">{title}</p>
            </div>
            <div className="contact-banner-sub">
              <p className="contact-banner-sub-main">{sub}</p>
            </div>
          </div>
          <div></div>
        </div>
      </Container>
    </div>
  );
};

export default BannerCard;
