import { Grid } from "@mui/material";
import React from "react";

const DemoFeatureCard = ({ data }) => {
  return (
    <div className="demo-feature-inner">
      <div className="demo-feature-title">
        <p className="demo-feature-title-main">{data.heading}</p>
      </div>
      <div className="demo-feature-sub">
        <p className="demo-feature-sub-main">{data.subhead}</p>
      </div>
      <div className="demo-feature-card">
        <div className="demo-feature-card-inner">
          <Grid container direction="row" spacing={5}>
            {data.demos.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <div className="demo-feature-item">
                  <div className="demo-feature-card-title">
                    <strong className="demo-feature-card-title-main">
                      {item.title}
                    </strong>
                  </div>
                  <div className="demo-feature-card-sub">
                    <p className="demo-feature-card-sub-main">{item.sub}</p>
                  </div>
                  <div className="demo-feature-card-icon-div">
                    <img
                      className="demo-feature-card-img"
                      src={item.img}
                      alt="demo"
                    />
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DemoFeatureCard;
