import { Grid } from "@mui/material";

const PaymentCard = ({ img, title, sub, link }) => {
  return (
    <div className="pay-card-grid">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <div className="pay-div-img">
            <img className="pay-img-main" src={img} alt="pay-img" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <div className="pay-para-content">
            <div className="pay-div-title">
              <p className="pay-title-main">{title}</p>
            </div>
            <div className="pay-div-sub">
              <p className="pay-sub-main">{sub}</p>
            </div>
            <div className="pay-div-link">
              <p className="pay-link-main">{link}</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentCard;
